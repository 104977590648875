import React, { useEffect, useState } from "react";
import { GetItemHistory, SetItemHistory } from "../store/itemAtom";
import { itemsService } from "../services/items.service";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const tableHeader = [
  {
    field: "History_Date",
    headerName: "Date",
    width: 160,
    headerClassName: "tableHeader ",
  },
  {
    field: "History_Time",
    headerName: "History Time",
    width: 160,
    headerClassName: "tableHeader ",
  },
  {
    field: "Changed_By_Name",
    headerName: "Change by Name",
    width: 160,
    headerClassName: "tableHeader ",
  },
  {
    field: "Action",
    headerName: "Action",
    width: 360,
    headerClassName: "tableHeader ",
  },
];

function History(props) {
  const [historyList, setHistory] = useState([]);
  // const setHistory = SetItemHistory();
  // const historyList = GetItemHistory();

  useEffect(() => {
    if (historyList.length < 1) {
      itemsService
        .getItemHistory(props.item_id)
        .then((res) => {
          const tempData = res.data?.map((item, i) => {
            return { ...item, id: i };
          });
          if (tempData?.length) {
            setHistory(tempData);
          } else {
            setHistory([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props?.item_id]);

  return (
    <>
      <h2 className="mt-4 font-bold text-xl text-gray-600">
        Sales Rate History
      </h2>
      <div className="mt-4 h-[500px] w-full overflow-x-scroll">
        <DataGrid
          columns={tableHeader}
          rows={historyList}
          components={{
            Pagination: CustomPagination,
          }}
        />
      </div>
    </>
  );
}

export default History;
