import { useEffect, useState } from "react";

function AddProductPlanWidget(props) {
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    if(props.defaultVal === 1){
        setIsChecked(true);
    }
    
  }, [props.defaultVal]);



  const onCheckAction = (check) => {
    console.log('check ', check);
    setIsChecked(check);
    props.includeWidget(check);
  };

  return (
    <>
      <div className="mt-2 flex text-sm">
        <div className="ml-3 flex flex-row items-center space-x-1.5">
     
             <input
               checked={isChecked}
            onChange={(e) => onCheckAction(e.target.checked)}
                type="checkbox"
                className="w-[0.8vw] h-[1.6vh]"
                value="2"
                />

          <div className="text-xs font-normal text-gray-600">Include in Widget</div>
        </div>
      </div>
    </>
  );
}

export default AddProductPlanWidget;
