/* eslint-disable jsx-a11y/anchor-is-valid */

import { createContext, useState, useContext } from "react";
import Box from "@mui/material/Box";
import LogicContext from "../Context/LogicContext";
import Modal from "@mui/material/Modal";
import "../pages/NewModel.css";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { itemsService } from "../services/items.service";
import { accountService } from "../services/accountService";
import { SetItemMasterData } from "../store/itemAtom";
import axios from "axios";
import { authHeader } from "../helpers/authHeader";
import {
  SetChartTableFilter,
  SetChartList,
  SetChartTableHead,
} from "../store/chartOfAccountAtom";
import { MdEdit } from "react-icons/md";
import { twMerge } from "tailwind-merge";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-40%, -40%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NotificationContext = createContext({
  showModal: ({}) => {},
  closeModal: () => {},
  getMasterData: () => {},
  reloadChartList: () => {},
});

export function NotificationContextProvider(props) {
  const [open, setOpen] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const setItemMasterData = SetItemMasterData();
  const logicContext = useContext(LogicContext);
  const [tableItems, setTableItems] = useState([]);
  const [itemModify, setItemModify] = useState({});
  const [serverProps, setServerProps] = useState({});

  const setChartFilter = SetChartTableFilter();
  const setChartTablehead = SetChartTableHead();
  const subchartList = SetChartList();

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setItemModify({});
    setServerProps({});
    reset({});
    setTableItems([]);
    setNewRecord(false);
  };

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const openModalHandler = (serverProps) => {
    setOpen(true);
    setItemModify({ Status_Name: "Active" });
    setServerProps(serverProps);
    axios
      .get(
        `/get-category-master-data/${serverProps.sql_ref_no_get}/${serverProps.option_get}`,
        authHeader()
      )
      .then((res) => {
        if (res?.data?.length) {
          setTableItems(res?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const LoadMasterData = () => {
    itemsService
      .getItemsMasterData()
      .then((res) => {
        setItemMasterData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  function editItem(item) {
    setValue("Name", item.Name);
    setValue("Status_Name", item.Status_Name);
    setValue("code", item.Id);
    setItemModify(item);
  }

  function changeStatus(status) {
    setValue("Status_Id", status);
    setItemModify((prev) => ({ ...prev, Status_Id: status }));
  }

  function newRecordHandler() {
    setNewRecord(true);
    setItemModify({});
    reset({});
  }

  function reloadChartOfAccount() {
    accountService.getChartOfAccount().then((res) => {
      if (res?.data !== undefined) {
        subchartList(res.data["Chart of Accounts"]);
        setChartFilter(res.data["Filtering Criteria"]);
        setChartTablehead(res.data["Display Column"]);
      }
    });
  }

  function updateItemToServer() {
    let formData = new FormData();

    if (newRecord === true) {
      formData.set("sql_ref_no", serverProps.sql_ref_no_send);
      formData.set("option", serverProps.newOption);
      formData.set("Name", getValues("Name"));
      formData.delete("Code");
      formData.delete("Status");
      // formData.set('Code', itemModify.Id );
      // formData.set('Status', itemModify.Status_Id);
      axios
        .post("/new-master-data", formData, authHeader())
        .then((res) => {
          console.log("NEW_", res.data);
          if (res?.data?.master_info?.length) {
            setTableItems(res?.data.master_info);
          }
          setNewRecord(false);
          LoadMasterData();

          logicContext.showToast(
            "New item has been created successfully!",
            "success"
          );
        })
        .catch((err) => {
          logicContext.showToast("Connection error !", "error");
        });
    } else {
      formData.set("sql_ref_no", serverProps.sql_ref_no_send);
      formData.set("option", serverProps.updateOption);
      formData.set("Name", getValues("Name"));
      formData.set("Code", itemModify.Id);
      formData.set("Status", itemModify.Status_Id);
      axios
        .put("/update-master-data", formData, authHeader())
        .then((res) => {
          if (res?.data?.master_info?.length) {
            setTableItems(res?.data.master_info);
          }
          LoadMasterData();
          logicContext.showToast("Item update was successful!", "success");
        })
        .catch((err) => {
          logicContext.showToast("Connection error !", "error");
        });
    }
  }

  const context = {
    showModal: openModalHandler,
    getMasterData: LoadMasterData,
    reloadChartList: reloadChartOfAccount,
  };

  return (
    <NotificationContext.Provider value={context}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="relative mt-8 w-full h-full max-h-[510px] max-w-xl mx-auto bg-white rounded-2xl shadow-xl">
          <div className="flex items-center justify-end mt-2 mx-2">
            <button
              onClick={handleClose}
              className="mt-2 p-2 rounded-full hover:bg-gray-200 active:bg-gray-100 focus:outline-none"
            >
              <span className="text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className="px-4 pb-2">
            <div className="flex justify-between">
              <div>
                <label className="text-xs font-medium text-gray-600">
                  Name
                </label>
                <input
                  {...register("Name", {
                    required: "Item name is required",
                  })}
                  className="w-full z-10 text-sm rounded-md outline-none bg-white placeholder:text-sm placeholder:text-gray-400 border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                  type="text"
                  placeholder="Item name"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-xs font-medium text-gray-600">
                  Status
                </label>
                <div className="bg-white w-fit">
                  <a
                    className="text-gray-600 text-xs"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span
                      className={twMerge(
                        "font-semibold text-gray-600 boder-[1.5px] border-gray-200 flex items-center rounded-md select"
                      )}
                    >
                      {itemModify?.Status_Name}
                    </span>
                  </a>
                  <ul
                    className={twMerge("dropdown-menu")}
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li onClick={() => changeStatus("Active")}>
                      <a className="dropdown-item text-xs" href="#">
                        Active
                      </a>
                    </li>
                    <li onClick={() => changeStatus("Inactive")}>
                      <a className="dropdown-item text-xs" href="#">
                        Inactive
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-6">
              {newRecord ? null : (
                <div className="relative w-full max-w-sm mx-auto">
                  <input
                    className="h-[40px] flex items-center pl-10 pr-4 text-sm placeholder:text-sm placeholder:text-gray-400 w-full bg-white ring-1 ring-gray-300 rounded-full outline-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Search"
                  />

                  <button className="absolute left-3 top-2.5 w-fit text-gray-500 focus:outline-none outline-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <div
              className={`mt-3 ${
                newRecord ? "h-[300px]" : "h-[250px]"
              }  mb-1 overflow-y-scroll scrollbar-thumb-gray-400 scrollbar-thin scrollbar-track-gray-200 pb-[200px]`}
            >
              <table className="w-full text-left table-auto">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-gray-600">ID</th>
                    <th className="px-4 py-2 text-gray-600">Name</th>
                    <th className="px-4 py-2 text-gray-600">Status</th>
                    <th className="px-4 py-2 text-gray-600">Edit</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 pb-40">
                  {tableItems?.map((item, i) => (
                    <tr
                      key={i}
                      className="text-xs font-medium text-gray-700 hover:bg-gray-50"
                    >
                      <td className="px-4 py-2">{item.Id}</td>
                      <td className="px-4 py-2">{item.Name}</td>
                      <td className="px-4 py-2">{item.Status_Name}</td>
                      <td className="px-4 py-2">
                        <div
                          className="w-fit p-1.5 rounded hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
                          onClick={() => editItem(item)}
                        >
                          <MdEdit className="h-4 w-4 text-blue-500" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="absolute bottom-3 right-3 flex flex-row items-center space-x-3">
              {newRecord ? (
                <button
                  onClick={() => updateItemToServer()}
                  className="px-4 py-2.5 bg-blue-500 rounded-md text-white focus:outline-none font-medium hover:bg-blue-400 active:bg-blue-500 transition-all duration-200"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => updateItemToServer()}
                  className="px-4 py-2.5 bg-blue-500 rounded-md text-white focus:outline-none font-medium hover:bg-blue-400 active:bg-blue-500 transition-all duration-200"
                >
                  Update
                </button>
              )}

              <button
                onClick={() => newRecordHandler()}
                className="px-4 py-2.5 bg-blue-500 rounded-md text-white focus:outline-none font-medium hover:bg-blue-400 active:bg-blue-500 transition-all duration-200"
              >
                New
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
