import { useEffect, useState } from "react";


function InvoiceGiftCheck(props) {
    const [isChecked, setIsChecked] = useState(false);  
    

    useEffect(()=> {
        if(props.defaultVal === 1){
            setIsChecked(true)
        }
      },[props.defaultVal]);


      const handleOnChange = () => {
        setIsChecked(!isChecked);
        if(isChecked){
            props.onGiftCheck(2)
        }else{
            props.onGiftCheck(1)
        }
        
      };



    return ( 
        <>
          <input  checked={isChecked}  onChange={handleOnChange}
                      className="  bottom-0 outline-none  focus:ring-0"
                      type="checkbox"
                    />
      
        </>
     );
}

export default InvoiceGiftCheck;