import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../../services/items.service';
import { SetGroupImageEdit, GetGroupImageEdit  } from "../../store/itemGroupAtom";


const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };



function ItemGroupPrimaryImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const getGroupImageEdit = GetGroupImageEdit();
    const setGroupImageEdit = SetGroupImageEdit();

    console.log('Picture_id ', props?.Picture_Id);

  useEffect(()=> {
    if(props?.defaultImage){
      setImageUrl(props.defaultImage);
    }
  },[props?.defaultImage]);

  
    const handleFileChange = (info) => {
      const { file } = info;
      if (file.status === 'error') { // Since our is dynamic upload, we use this status
        
        const formData = new FormData();
        const myFile = file.originFileObj;
        formData.append('file', myFile);
            const formObject = {item_group_id: props?.Item_Id, picture_id: props?.Picture_Id || 0 ,  option: props?.Picture_Id ? 4 : 1}
        formData.append('formObject', JSON.stringify(formObject));
        setLoading(true);
        itemsService.submitItemGroupImage(formData).then(res => {
          setLoading(false);
          const newUrl =  res.data?.file_link;
          setImageUrl(newUrl);
          const newImageList =  getGroupImageEdit.map((img)=> {
            console.log('Image_list ', newImageList);
            if(img.Picture_Id === props?.Picture_Id){
              return {...img, Picture_URL : newUrl, Picture_Id: props?.Picture_Id}
            }else{
              return img;
            }
          });
          setGroupImageEdit(newImageList);
  
        }).catch((err)=> {
          setLoading(false);
        })

      }
  
    
  
    };
  

  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleFileChange}
        multiple={false}
      >
        {imageUrl ? (
          <>
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
         
          </>
          
        ) : (    uploadButton  )}
      </Upload>

      
        </>
     );
}

export default ItemGroupPrimaryImageUpload;