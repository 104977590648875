import React, { useEffect, useState, useContext, useRef } from "react";
import { invoiceService } from "../../services/invoice.service";
import Select from "react-select";
import iconImage from "../../assets/Icon.svg";
import CurrencyFormat from "react-currency-format";
import { useParams } from "react-router-dom";
import underscore from "underscore";
import LogicContext from "../../Context/LogicContext";
import {
  SetInvoiceEntryMasterData,
  GetInvoiceEntryMasterData,
  GetInvoiceEdit,
  ResetInvoiceEdit,
} from "../../store/invoiceAtom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import AddInvoiceTable from "./AddInvoiceTable";
import AddInvoiceCustomer from "./AddInvoiceCustomer";
import AddOnInvoiceTermsSelect from "./AddInvoiceTermsSelect";
import InvoiceSalesPersonSelect from "./InvoiceSalesPersonSelect";
import InvoiceCreatedBySelect from "./InvoiceCreatedBy";
import InvoiceWareHouseSelect from "./InvoiceWareHouseSelect";
import InvoiceGiftCheck from "./InvoiceGiftCheck";
import InvoiceDiscountType from "./InvoiceDiscountType";
import GeneralFileUploadPreview from "../../components/GeneralFileUploadPreview";



function AddInvoicePage() {
  const setMasterData = SetInvoiceEntryMasterData();
  const masterData = GetInvoiceEntryMasterData();
  const invoiceToEdit = GetInvoiceEdit();
  const resetInvoiceToEdit = ResetInvoiceEdit();


  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const logicContext = useContext(LogicContext);
  const [itemTableList, setItemTableList] = useState([]);
  const [subtotal, setSubTotal] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [adjustmentValue, setAdjustmentValue] = useState(0);
  const [grandTotalSum, setGrandTotalSum] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [attachedFile, setAttachedFile] = useState(null);


  const adjustmentRef = useRef(null);
  const shippingRef = useRef(null);
  const discountRef = useRef(null);

  useEffect(() => {
    setShippingValue(0);
    setAdjustmentValue(0);
    setDiscountValue(0);
    console.log('invoice_edit ', invoiceToEdit);

    if (invoiceToEdit?.Invoice_Ref_No) {
      setValue("Customer_Id", invoiceToEdit?.Customer_Id);
      setValue("Order_Number", invoiceToEdit?.Order_NO ? invoiceToEdit?.Order_NO : '');
      setValue("Payment_Term_Id", invoiceToEdit?.Payment_Term_Id);
      setValue("Sales_Person_Id", invoiceToEdit?.Sales_Person_Id);
      setValue("invoice_ref_no", invoiceToEdit?.Invoice_Ref_No);
      setValue("Warehouse_Id", invoiceToEdit?.Warehouse_Id);
      setValue("Payment_Reference", invoiceToEdit?.Payment_Reference ? invoiceToEdit?.Payment_Reference : '');
      setValue("Payment_Reference_No", invoiceToEdit?.Payment_Reference_No ? invoiceToEdit?.Payment_Reference_No :'');
      setValue("Gift_Id", invoiceToEdit?.Gift_Id);
      setValue("Discount_Amount", invoiceToEdit?.Discount_Amount);
        setDiscountValue(invoiceToEdit?.Discount_Amount);
      setValue("Shipping_Amount", invoiceToEdit?.Shipping_Amount);
      setShippingValue(invoiceToEdit?.Shipping_Amount)
      setValue("Adjustment_Amount", invoiceToEdit?.Adjustment_Amount);
      setAdjustmentValue(invoiceToEdit?.Adjustment_Amount)
      setValue("Customer_Notes", invoiceToEdit?.Customer_Notes);
      setValue("Discount_Type_Id", invoiceToEdit?.Discount_Type_Id);
      // setValue("Attached_File_Id", invoiceToEdit?.Attached_File_Id);
     
    }

    return () => {
      resetInvoiceToEdit();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceToEdit]);



  useEffect(()=> {
    if (masterData["Customers"] === undefined) {
      invoiceService.entryMasterData().then((res) => {
        if (res?.data !== undefined) {
          setMasterData(res.data);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])




  const onSelectionList = (list) => {
    setItemTableList(list);
    calculateGrandSum(list);
  };



  function calculateGrandSum(list) {
    let tempTotal = 0;
    list?.forEach((item) => {
      tempTotal +=
        parseInt(item.Plan_Sales_Quantity) * parseInt(item.Plan_Sales_Rate);
    });
    setSubTotal(tempTotal);


    const discountType = parseInt(getValues("Discount_Type_Id"));

    
    const discount = parseInt(discountRef?.current?.value)
 ? parseInt(discountRef?.current?.value) : 0;

    const shipping = parseInt(shippingRef?.current?.value)
    ? parseInt(shippingRef?.current?.value) : 0;
    const adjustment = parseFloat(adjustmentRef?.current?.value)
    ? parseFloat(adjustmentRef?.current?.value) : 0;
    
    console.log('discount ', discount);
    console.log('adjustment ', adjustment);
    console.log('grandTotalSum ', grandTotalSum);
    // Temporaly disable this cos of error
    // if ((discount + adjustment) > grandTotalSum){
    //   return logicContext.showToast('Discount/Adjustment cannot be less than Total amount!');
    // }

    let gTotal = tempTotal + shipping;
    gTotal = gTotal += adjustment;
    if (discountType === 1) {
      gTotal = gTotal - discount;
    }
    if (discountType === 2) {
      let temDiscount = (discount / 100) * gTotal;
      gTotal = gTotal - temDiscount;
    }

    setGrandTotalSum(gTotal);
  }


  const onChangeShipping = (e) => {
    try {

    if (parseFloat(e?.target?.value) >= 0) {
      setShippingValue(parseFloat(e.target.value));
      setValue("Shipping_Amount", parseFloat(e.target.value));
      calculateGrandSum(itemTableList);
    }
      
    } catch (error) {
  
    }

  };


  const onDiscountValueChange = (e) => {
    try {
      if (parseFloat(e.target.value) >= 0) {
        setDiscountValue(parseFloat(e.target.value));
        setValue("Discount_Amount", parseFloat(e.target.value));
        calculateGrandSum(itemTableList);
      } else {
        setDiscountValue(0);
        setValue("Discount_Amount", 0);
        calculateGrandSum(itemTableList);
      }
      
    } catch (error) {
      setDiscountValue(0);
      setValue("Discount_Amount", 0);
      calculateGrandSum(itemTableList);
      
    }

  };


  const onAdjustmentChange = (e) => {
    try {
      const val = e.target.value.trim()
      if (Number(val)) {
        setAdjustmentValue(Number(val));
        setValue("Adjustment_amount", Number(val));
        calculateGrandSum(itemTableList);
      } else {
        setAdjustmentValue(0);
        setValue("Adjustment_amount", 0);
        calculateGrandSum(itemTableList);
      }
      
    } catch (error) {
      setAdjustmentValue(0);
      setValue("Adjustment_amount", 0);
      calculateGrandSum(itemTableList);
      
    }
  
  };


  function onDiscountTypeSelect(val) {
    setValue("Discount_Type_Id", val);
    calculateGrandSum(itemTableList);
  }


  function onCustomerSelect(val) {
    setValue("Customer_Id", val.Customer_Id);
  }


  function onPaymentTermsSelect(val) {
    setValue("Payment_Term_Id", val.Payment_Term_Id);
  }


  function onSalesPersonSelect(val) {
    console.log('sales_person ', val);
    setValue("Sales_Person_Id", val.User_Id);
  }


  function onSalesCreatedBySelectSelect(val) {
    setValue("User_Id", val.User_Id);
  }

  function onGiftCheck(code) {
    setValue("Gift_Id", code);
  }

  function onWareHouseSelect(val) {
    console.log('sales_person ', val);

    setValue("Warehouse_Id", val.Warehouse_Id);
  }

  
  const onSubmit = async () => {
    var tempStringList = itemTableList?.map((item) => {
    
      let temp = `<a> <plan_id>${item.Plan_Id}  </plan_id>
        <minimum_amount>${2} </minimum_amount>
        <rate>${item?.Plan_Sales_Rate} </rate>
        <quantity>${item?.Plan_Sales_Quantity ? item?.Plan_Sales_Quantity : 0 } </quantity>
        <tax_id>${0} </tax_id>
          </a>`;
      return temp;
    });

    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((res) => {
        if (res.decision === true) {
          const newData = getValues();
          let option = 1;
          sessionStorage.setItem('reloadPage', 'true'); // For reloading main table page
          if(invoiceToEdit?.Invoice_Ref_No){
            option = 3;
            sessionStorage.removeItem('reloadPage');
          }
         
          const formData = new FormData();
          for( const keyData in newData){
              formData.append(keyData, newData[keyData] ? newData[keyData] : 0 );
          }
          formData.append('option', option);
          formData.append('xml_string_plan', `<root> ${tempStringList} </root>`);
          if(attachedFile){
            formData.set('file', attachedFile)
          }

          invoiceService.submitInvoice(formData).then((res) => {
              if (res?.data !== undefined) {
                const { message_text, message_id } = res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(message_text, "success");
                  navigate(-1);
                } else {
                  return logicContext.showToast(message_text, "error");
                }
              }
            });
        }
      })
      .catch((err) => {
        logicContext.showToast("error submitting record", "error");
      });
  };


  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }



  function onFileSelected(file) {
    setAttachedFile(file);
    
  }


  function dueDatefunction(){
 
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(invoiceToEdit?.Due_Date_String !== undefined){
       defaultDate = moment(invoiceToEdit?.Due_Date_String, "DD-MM-YYYY").format("YYYY-MM-DD");
       console.log('date_modified ', defaultDate);
    }
   
    return (
    <input
    defaultValue={defaultDate}
   key={defaultDate}
    className="bg-filterInput h-[8vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
    type="date"
    {...register("Due_Date", {
      required: "Due date is required",
    })}
  />
    )
  }


  function orderDateFunction(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(invoiceToEdit?.Invoice_Date_String !== undefined){
       defaultDate = moment(invoiceToEdit?.Invoice_Date_String, "DD-MM-YYYY").format("YYYY-MM-DD")
    }
   
    return (
      <input
      defaultValue={defaultDate}
      className="bg-filterInput h-[8vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
      type="date"
      {...register("Order_Date", {
        required: "Order date is required",
      })}
    />
    )
  }


  function invoiceDateFunction(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(invoiceToEdit?.Invoice_Date_String !== undefined){
       defaultDate = moment(invoiceToEdit?.Invoice_Date_String, "DD-MM-YYYY").format("YYYY-MM-DD");
       console.log('Invoice_Date_String_d ', defaultDate);
    }
   
    return (
      <input 
      defaultValue={defaultDate}
      className="bg-filterInput h-[8vh] pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
      type="date"
      {...register("Invoice_Date", {
        required: "Invoice date is required",
      })}
    />
    )
  }




  return (
    <>
      <>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="container max-auto  ">
            <Grid container spacing={2}>
            
              <Grid item xs={6}>
                

              <span className="text-sm text-danger ">Customer Name</span> 
              <Grid container>
              <Grid  item xs={2}>
              <button className="flex outline-none items-center justify-center px-4  ">
                       <svg
                       className="  outline-none border-transparent focus:border-transparent"
                         width="48"
                         height="50"
                         border= "none"
                         viewBox="0 0 24 24"
                         fill="bg-inputColor"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="M16.4996 10.875C16.4996 11.9875 16.1697 13.0751 15.5516 14.0001C14.9335 14.9251 14.055 15.6461 13.0272 16.0718C11.9994 16.4976 10.8684 16.609 9.77723 16.3919C8.68608 16.1749 7.6838 15.6391 6.89713 14.8525C6.11046 14.0658 5.57473 13.0635 5.35769 11.9724C5.14065 10.8812 5.25204 9.75024 5.67779 8.72241C6.10353 7.69457 6.8245 6.81607 7.74953 6.19798C8.67455 5.5799 9.76209 5.25 10.8746 5.25C12.3659 5.25169 13.7957 5.84487 14.8502 6.89939C15.9047 7.95392 16.4979 9.38368 16.4996 10.875ZM21.5294 21.5304C21.3888 21.6711 21.198 21.7501 20.9991 21.7501C20.8001 21.7501 20.6094 21.6711 20.4687 21.5304L16.4165 17.4783C14.7122 18.9092 12.5213 19.6269 10.3006 19.4817C8.08 19.3366 6.00111 18.3398 4.49749 16.6993C2.99387 15.0587 2.18161 12.9011 2.23008 10.6762C2.27855 8.45139 3.18402 6.33114 4.75766 4.75763C6.3313 3.18413 8.45163 2.27884 10.6765 2.23057C12.9013 2.18229 15.0589 2.99474 16.6993 4.4985C18.3398 6.00226 19.3363 8.08123 19.4813 10.3019C19.6262 12.5225 18.9083 14.7134 17.4773 16.4176L21.5294 20.4698C21.6701 20.6104 21.7491 20.8012 21.7491 21.0001C21.7491 21.199 21.6701 21.3898 21.5294 21.5304ZM10.8746 18C12.2838 18 13.6613 17.5821 14.833 16.7992C16.0047 16.0163 16.918 14.9035 17.4573 13.6016C17.9965 12.2997 18.1376 10.8671 17.8627 9.48498C17.5878 8.10287 16.9092 6.83331 15.9127 5.83686C14.9163 4.84042 13.6467 4.16182 12.2646 3.8869C10.8825 3.61199 9.44991 3.75308 8.14799 4.29236C6.84607 4.83163 5.73329 5.74486 4.95039 6.91656C4.16748 8.08826 3.74961 9.46581 3.74961 10.875C3.75175 12.764 4.5031 14.575 5.83883 15.9108C7.17457 17.2465 8.9856 17.9979 10.8746 18Z"
                           fill="#45444B"
                         />
                       </svg>
                     </button>

              </Grid>
              <Grid item xs={10}>
              <AddInvoiceCustomer   defaultVal={{label: invoiceToEdit?.Customer_Full_Name }}
               onCustomerSelect={onCustomerSelect} />
                </Grid>

              </Grid>
              
                
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Warehouse Name </span>
                  <div className="">
                    <InvoiceWareHouseSelect onWareHouseSelect={onWareHouseSelect}
                     defaultVal={{label: invoiceToEdit?.Warehouse_Name}}/>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Invoice Number</span>
                  <div className="">
                    <input
                      className="bg-filterInput h-[8vh] pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                       readOnly={true} placeholder="Auto Generated Number"
                      value={invoiceToEdit?.Invoice_Ref_No}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm text-danger ">Invoice Date</span>
                  <div className="">
                 {invoiceDateFunction()}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Order Number</span>
                  <div className="">
                    <input
                      className="bg-filterInput h-[8vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      {...register("Order_Number", {
                      })}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm  ">Order Date</span>
                  <div className="">
                  {orderDateFunction()}
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <span className="text-sm ">Terms</span>
                <AddOnInvoiceTermsSelect onPaymentTermsSelect={onPaymentTermsSelect}
                  defaultVal={{label: invoiceToEdit?.Payment_Term_Name}} />
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm text-danger ">Due Date</span>
                  <div className="">
              
                   {dueDatefunction()}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Sales Person</span>
                <InvoiceSalesPersonSelect onSalesPersonSelect={onSalesPersonSelect}
                defaultVal={{label: invoiceToEdit?.Sales_Person_Name}} />
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Created By</span>
                <InvoiceCreatedBySelect  onSalesCreatedBySelectSelect={onSalesCreatedBySelectSelect}
                defaultVal={{label: invoiceToEdit?.Created_User_Full_Name}} />
              </Grid>

              <Grid item xs={6}>
                <div className="flex">
                  <span className="text-sm mt-1">Is Gift ?</span>
                  <div className=" ml-5 ">
                    <InvoiceGiftCheck  defaultVal={ invoiceToEdit?.Gift_Id}
                      onGiftCheck={onGiftCheck} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
              <span className="text-sm ">Subscription Ref. No.</span>
              <div className="">
                    <input
                      className="bg-filterInput h-[8vh] w-full rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="readOnly"
                      value={invoiceToEdit?.Subscription_Ref_No}
                  
                    />
                  </div>
          
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Payment Reference</span>
                  <div className="">
                    <input
                      className="bg-filterInput h-[8vh] pl-3  rounded w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      {...register("Payment_Reference", {})}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Payment Reference Number</span>
                  <div className="">
                    <input
                      className="bg-filterInput h-[8vh] pl-3  rounded w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      {...register("Payment_Reference_No", {})}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <AddInvoiceTable onSelectionList={onSelectionList}
                defaultTableData={invoiceToEdit['invoice details']} ></AddInvoiceTable>
              </Grid>
              
              <Grid item xs={12}>
                <div className="my-2 border-2 px-2 py-2   ml-[20vw] ">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <span className="#476ef8">Sub Total</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span className="#476ef8"></span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="#476ef8 right-0">
                        <CurrencyFormat  value={parseFloat(subtotal)?.toFixed(2)}
                          displayType={"text"} thousandSeparator={true}prefix={"₦"} />
                      </span>
                    </Grid>

            

                    <Grid item xs={4}>
                      <span className="#476ef8 ">
                        <span className="text-sm ">Discount</span>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center"> 
                      <span className="text-sm">
                        <div className="">
                          <div className=" ">
                            <div className="">
                              <label className="">
                                <span className=" relative">
                                  <input
                                    className="bg-filterInput rounded pl-3 h-[7vh] w-full z-50 bottom-0 
                        outline-none border-transparent focus:border-transparent focus:ring-0"
                                    type="number"
                                    placeholder=""
                                    name="discountRef"
                                    {...register("Discount_Amount", {})}
                                    ref={discountRef}
                                    key={discountValue}
                                    autoFocus="autoFocus"
                                    onChange={onDiscountValueChange}
                                    defaultValue={discountValue}
                                    // key={discountValue}
                                  />
                                  <span className=" border-2  absolute -top-4 right-0 ">
                                    <InvoiceDiscountType onDiscountTypeSelect={ onDiscountTypeSelect}
                                    defaultVal={invoiceToEdit?.Discount_Type_Id} />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <small className="text-danger">
                              {" "}
                              {errors?.Coupon_Discount &&
                                errors.Coupon_Discount.message}
                            </small>
                          </div>
                        </div>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="text-sm">
                        <CurrencyFormat value={parseFloat(discountValue)?.toFixed(2)}
                          displayType={"text"} thousandSeparator={true} prefix={""}/>
                      </span>
                    </Grid>
                    <Grid item xs={4}>
                      <span className="text-sm">
                        {" "}
                        Shipping Charges <br></br>
                        <i style={{ color: "#476EF8" }}>
                          Apply Tax on shipping Charge
                        </i>
                      </span>{" "}
                    </Grid>

                    <Grid item xs={4} className="flex justify-center">
                      <span className="">
                        <input
                          className="bg-filterInput mt-2 h-[7vh] pl-3 rounded  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          name="shipping_amount"
                          {...register("Shipping_Amount", {})}
                          placeholder="" ref={shippingRef} onChange={onChangeShipping}
                          autoFocus="autoFocus"
                          key={shippingValue}
                          defaultValue={shippingValue} />
                      </span>
                    </Grid>

                    <Grid item xs={4} className="flex justify-center">
                    <span className="#476ef8 ">
                        <span>
                          {" "}
                          <span className="text-sm">
                            <CurrencyFormat 
                              value={parseFloat(shippingValue)?.toFixed(2)}
                              displayType={"text"} thousandSeparator={true}prefix={"₦"}  />
                          </span>
                        </span>
                      </span>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="">
                        <input
                          className="bg-filterInput h-[7vh] w-full rounded bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="Adjustment"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <div className="">
                        <input

                          className="bg-filterInput h-[7vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number" step="any"

                          name="shipping_amount"
                          {...register("Adjustment_Amount", {})}
                          defaultValue={adjustmentValue}
                          autoFocus="autoFocus"
                          key={adjustmentValue} ref={adjustmentRef} onChange={onAdjustmentChange} />
                      </div>
                    </Grid>

                    <Grid item xs={4} className="flex justify-center">
                    <span className="#476ef8 ">
                        <span>
                          {" "}
                          <span 
                          >
                            <CurrencyFormat
                            className={"text-sm " +(adjustmentValue < 0? 'text-danger': 'text-success' ) }
                              value={parseFloat(adjustmentValue)?.toFixed(2)}
                              displayType={"text"}  thousandSeparator={true} prefix={"₦"} />
                          </span>
                        </span>
                      </span>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="px-3">
                        <div>
                          <span className="text-xl  font-bold">Total (₦)</span>{" "}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className=" text-xl font-bold">
                        <CurrencyFormat
                          value={parseFloat(grandTotalSum)?.toFixed(2)}
                          displayType={"text"}  thousandSeparator={true}  prefix={"₦"}  />
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="">
                        <span className="text-sm ">Customer Notes</span>
                        <div className="">
                          <input
                            className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="text"
                            {...register("Customer_Notes", {})}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="">
                        <span className="text-sm ">Terms and Conditions</span>
                        <div className="">
                          <input
                            type="readOnly"
                            className="bg-filterInput rounded h-[15vh] w-full pl-3 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            placeholder="Enter the terms and conditions of your business to 
                            be displayed in your transaction"
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                   <div className="">
                  <span className="text-sm ">Attach File</span> 
                <GeneralFileUploadPreview defaultFileUrl={invoiceToEdit?.Attachement_URL} 
                onFileSelected={onFileSelected} />
                  <i> maximum file size 2MB </i>
                </div>
           
              </Grid>
              <Grid item xs={12}>
                <section>
                  <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    <div
                      style={{ color: "#F17E7E", backgroundColor: "white" }}
                      className="text-sm btn btn-danger rounded" onClick={()=> navigate(-1)} >
                      CANCEL
                    </div>
                    <div>
                      <span
                        style={{ color: "#476EF8" }}
                        className="text-sm cursor   px-3 rounded mr-10 py-2"
                      >
                        SAVE AS DRAFT
                      </span>
                      <button
                        type="submit"
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </section>
        </form>
      </>
    </>
  );
}

export default AddInvoicePage;
