/* eslint-disable jsx-a11y/anchor-is-valid */

// import './AddItemPage.css';
import { useEffect, useState } from "react";
import { itemsService } from "../../services/items.service";
import AddItemForm from "./AddItemFormProps/AddItemForm";
import {
  GetItemToEdit,
  GetItemImageToEdit,
  SetItemImageToEdit,
} from "../../store/itemAtom";
import AddAssciates from "../AddAssciates";
import { Tabs } from "antd";
import AddItemImageUpload from "./Add-items-props/AddItemImageUpload";
import Tooltip from "@mui/material/Tooltip";
import goback from "../../assets/goback.svg";
import { useNavigate, useParams } from "react-router-dom";

function AddItemPage() {
  const itemToEditData = GetItemToEdit();
  const getListToEdit = GetItemImageToEdit();
  const [tabActiveKey, setTabActiveKey] = useState("1");
  const navigate = useNavigate();






  const changeTab = (key) => {
    setTabActiveKey(key);
  };

 
  return (
    <>
      <Tooltip title="Go back to previous page">
        <button
          onClick={() => navigate(-1)}
          className="h-7 w-7 flex items-center justify-center rounded-full hover:bg-gray-200 text-gray-700 focus:outline-none active:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
      </Tooltip>

      <div className="  md:mt-2 flex ">
        <Tabs
          activeKey={tabActiveKey}
          onChange={(key) => setTabActiveKey(key)}
          defaultActiveKey="1"
          items={[
            {
              label: "Item Information",
              key: "1",
              children: (
                <AddItemForm
                  changeTab={(val) => changeTab(val)}
                />
              ),
            },
            {
              label: "Associates Items",
              key: "2",
              children: (
                <AddAssciates item_id={itemToEditData?.Item_Id}></AddAssciates>
              ),
              disabled:
                !itemToEditData?.Item_Id ||
                itemToEditData?.Item_Variety_Id !== 2,
            },
            {
              label: "Item Pictures",
              key: "3",
              children: (
                <AddItemImageUpload
                  getListToEdit={getListToEdit}
                  Item_Id={itemToEditData?.Item_Id}
                />
              ),
              disabled: !itemToEditData?.Item_Id,
            },
          ]}
        />
      </div>
    </>
  );
}

export default AddItemPage;
