import { useEffect, useState } from "react";

function AddonWidget(props) {
    const [widget, setWidget] = useState(false); 
    const [allowAssoc, setAllowAssoc] = useState(false); 


    useEffect(()=> {
        if(props.allowPortal === 1){
            setWidget(true);
        }else if(props.widget === 1){
            setAllowAssoc(true);
        }
      
      },[props.allowPortal, props.widget ])

    const oneWidgetChange = (e) => {
        setWidget(e.target.checked);
        if(e.target.checked){
                 props.onWidgetCheck(1);
        }else{
            props.onWidgetCheck(0);
        }
       
      };

      const onAssoChange = (e) => {
        setAllowAssoc(e.target.checked);
       
        if(e.target.checked){
            props.onAllowAssoSelect(1);
            }else{
                props.onAllowAssoSelect(0);
            }
         };



    return ( 
        <>
          <div className=" text-sm mt-3 ">
                        <div className=" flex">
                          <div>
                            {" "}
                            <input
                              type="checkbox"
                              checked={widget}  onChange={(e)=> oneWidgetChange(e)}
                            //   {...register("Addon_Widget_Id")}
                              className="w-[0.8vw] h-[1.6vh]"
                              value="1"
                            />
                          </div>
                          <div className="mt-[0.3vh]">
                            {" "}
                            <span className="text-xs ml-2">
                              Include in Widget
                            </span>
                          </div>
                        </div>

                        <div className=" flex">
                          <div>
                            {" "}
                            <input
                              type="checkbox"
                              checked={allowAssoc}  onChange={(e)=> onAssoChange(e)}
                            //   {...register("Addon_Allow_Customer_Portal_Id")}
                              className="w-[0.8vw] h-[1.6vh]"
                              value="1"
                            />
                          </div>
                          <div className="mt-[0.3vh]">
                            {" "}
                            <span className="text-xs ml-2">
                            Allow customers to associate this addon with <br></br> their 
                            subscriptions from the Customer Portal
                            </span>
                          </div>
                        </div>
                
                      </div>
        </>
      );
}

export default AddonWidget;