import { useEffect, useState } from "react";

function ItemReturnable(props) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (props.defaultVal === 1) {
      setIsChecked(true);
    }
  }, [props?.defaultVal]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      props.addItemReturnable(2);
    } else {
      props.addItemReturnable(1);
    }
  };

  return (
    <>
      <div className="mt-10 flex flex-row items-center space-x-1">
        <input
          checked={isChecked}
          onChange={handleOnChange}
          type="checkbox"
          className="h-4 w-4 z-10 border-[1px] border-gray-500 rounded-sm focus:ring-0 ring-0 cursor-pointer"
        />
        <div className="text-sm text-gray-600 font-medium ml-2">
          Returnable Item
        </div>
      </div>
    </>
  );
}

export default ItemReturnable;
