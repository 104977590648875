import React, { useEffect } from "react";
import "./BundleHistory.css";
import { itemsService } from "../services/items.service";
import { DataGrid } from "@mui/x-data-grid";
import {
  GetItemBundleHistoryValue,
  SetItimBundleHistory,
} from "../store/itemAtom";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { createModal } from "react-modal-promise";
import EditProductConfirm from "../helpers/EditProductConfirm";
import GeneralModal from "../helpers/GeneralModal";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

const tableHeader = [
  {
    field: "Bundle_Date",
    headerName: "Date",
    width: 120,
    headerClassName: "tableHeader ",
  },
  {
    field: "Bundle_No",
    headerName: "Bundle No",
    width: 120,
    cellClassName: "text-primary",
    headerClassName: "tableHeader ",
  },
  {
    field: "Warehouse_Name",
    headerName: " Warehouse Name",
    width: 120,
    headerClassName: "tableHeader",
  },
  {
    field: "Item_Quantity",
    headerName: " Qty Bundled",
    width: 120,
    headerClassName: "tableHeader",
  },
  {
    field: "Total_Amount",
    headerName: " Total",
    width: 120,
    headerClassName: "tableHeader",
  },
  {
    field: "action",
    headerName: " Action",
    width: 120,
    cellClassName: "font-weight-bold text-primary",
  },
];

function ItemBundleHistory(props) {
  const setHistory = SetItimBundleHistory();
  const bundleHistory = GetItemBundleHistoryValue();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const { item_id } = useParams();
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const productionModal = createModal(EditProductConfirm);
  const myPromiseModal = createModal(GeneralModal);

  useEffect(() => {
    if (bundleHistory.length < 1) {
      itemsService.getItemBundleHistory(props.item_id).then((res) => {
        const historyList = res.data?.map((item, i) => {
          return {
            ...item,
            Item_Quantity: formatCustomNumber(item?.Item_Quantity),
            Total_Amount: formatCustomNumber(item.Total_Amount),
            id: i,
            action: "ADD NEW",
          };
        });
        if (historyList.length) {
          setHistory(historyList);
        } else {
          setHistory([]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item_id]);

  async function AddNewClick(ev) {
    if (ev.colDef.field === "action") {
      productionModal({
        title: "Confirmation",
        body: "Add or edit record",
      }).then((response) => {
        if (response === 1) {
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${
              ev.row.Bundle_No + "_" + 0
            }`
          );
        }
        if (response === 2) {
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${
              ev.row.Bundle_No + "_" + 1
            }`
          );
        }
      });
    }
    if (ev.colDef.field === "Bundle_No") {
      myPromiseModal({
        title: "Please confirm",
        body: "Do you want to go to the production entry?",
        showInput: false,
        placeHolder: "",
      }).then((res) => {
        if (res.decision === false) {
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${
              ev.row.Bundle_No + "_" + 1
            }`
          );
        }
      });
    }
  }

  return (
    <>
      <div className="mt-8 h-[500px] w-full overflow-x-scroll">
        <DataGrid
          columns={tableHeader}
          rows={bundleHistory}
          // onRowClick={( event) => {itemClickHandler(event)}}
          onCellClick={(event) => {
            AddNewClick(event);
          }}
        />
      </div>
    </>
  );
}

export default ItemBundleHistory;
