import React, { useContext, useEffect, useState } from "react";

import "../components/RateHistory.css";

import { GetItemRateHistory, SetItemRateHistory } from "../store/itemAtom";
import { itemsService } from "../services/items.service";
import { useForm } from "react-hook-form";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import underscore from "underscore";
import LogicContext from "../Context/LogicContext";
import { useParams } from "react-router-dom";
import AlertConfirm from "../helpers/AlertConfirm";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

const salesRateHeader = [
  {
    field: "Rate_Changed_Date",
    headerName: "Date",
    width: 160,
    headerClassName: "tableHeader ",
  },
  {
    field: "Changed_By_Name",
    headerName: "Changed By",
    width: 160,
    headerClassName: "tableHeader ",
  },
  {
    field: "Remark",
    headerName: "Reason",
    width: 260,
    headerClassName: "tableHeader ",
  },
  {
    field: "Total_Amount",
    headerName: "Rate",
    width: 190,
    headerClassName: "tableHeader ",
  },
  {
    field: "Item_Market_Rate",
    headerName: "Market Rate",
    width: 190,
    headerClassName: "tableHeader ",
  },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function RateHistory(props) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [sales, setSales] = useState([]);
  const [cost, setCost] = useState([]);
  const logicContext = useContext(LogicContext);
  const { item_id } = useParams();
  const [alertState, setAlertState] = useState(false);

  setValue("Item_Id", item_id);

  useEffect(() => {
    loadRateHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item_id]);


  const loadRateHistory = () => {
    itemsService.getItemRateHistory(props.item_id).then((res) => {
      console.log('Rate_ history ', res.data);
      
      if (res.data !== undefined) {
        var rateHistory = underscore.groupBy(res?.data, "Item_Rate_Type_Id");
      
        
        let rawSales = rateHistory[1]?.map((rate, idx)=> { return {...rate, Total_Amount: formatCustomNumber(rate?.Total_Amount),
           Item_Market_Rate:formatCustomNumber(rate?.Item_Market_Rate), id: idx + 1} });
        let rawCost = rateHistory[2]?.map((rate, idx)=> { return {...rate, Total_Amount: formatCustomNumber(rate?.Total_Amount),
          Item_Market_Rate:formatCustomNumber(rate?.Item_Market_Rate), id: idx + 1} });
        if (rawSales?.length) {
          setSales(rawSales);
        } else {
          setSales([]);
        }

        if (rawCost?.length) {
          setCost(rawCost);
        } else {
          setCost([]);
        }
      }
    })
    .catch((err) => {
      console.log('ERRR ', err);
      logicContext.showToast("No Rate history found!", "");
      setSales([]);
      setCost([]);
    });

  }

  const onSubmit = () => {
    setAlertState(true);
  };

  function onErrors(error) {
    console.error(error);
    logicContext.showToast("One or more field is required. Ensure Sales/Cost rate is checked", "error");
  }

  function closeAlert(response) {
    setAlertState(false);
    if (response === true) {
      let bodyData = getValues();
      itemsService.submitRateHistory({ ...bodyData, Option: 1 }).then((res) => {
          if (res.data !== undefined) {
            logicContext.showToast(res.data[0]["message_text"], "success");
            reset({});
            loadRateHistory();
          }
        })
        .catch((err) => {
          logicContext.showToast(
            "Error saving record, please try again",
            "error"
          );
        });
    }
  }

  return (
    <>
      {alertState ? (
        <AlertConfirm
          title={"Confirmation?"}
          body={"Do you want to save?"}
          closeAlert={closeAlert}
        ></AlertConfirm>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <div className="">
          <div className="mt-4 flex flex-row items-center space-x-6 ">
            <div className="flex flex-row items-center space-x-1">
              <input
                {...register("Item_Rate_Type_Id", {
                  required: "Type is required",
                })}
                type="radio"
                className="h-4 w-4 z-10 fill-zinc-100 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
                value="1"
              />
              <div className="text-sm font-medium text-gray-600">
                Sales Rate
              </div>
            </div>

            <div className="flex flex-row items-center space-x-1">
              <input
                type="radio"
                {...register("Item_Rate_Type_Id", {
                  required: "Type is required",
                })}
                className="h-4 w-4 z-10 fill-zinc-100 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
                value="2"
              />
             
              <div className="text-sm font-medium text-gray-600">Cost Rate</div>
            </div>
          </div>
          <small className="text-red-500">
                {errors?.Item_Rate_Type_Id && errors.Item_Rate_Type_Id.message}
              </small>

          <div className="mt-4 flex flex-row items-center space-x-4">
            <div className="w-full max-w-xs">
              <label className="text-sm text-gray-600 font-medium">NGN (Sales Rate) </label>
              <input
                className="-mt-1 h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                {...register("Item_Rate", {
                  required: "Item rate is required",
                })}
                type="number"
              />
                 <small className="text-red-500">
                {errors?.Item_Rate && errors.Item_Rate.message}
              </small>
            </div>
            <div className="w-full max-w-xs">
              <label className="text-sm text-gray-600 font-medium">NGN (Market Rate) </label>
              <input
                className="-mt-1 h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                {...register("Item_market_rate", {
                  required: "Item rate is required",
                })}
                type="number"
              />
                 <small className="text-red-500">
                {errors?.Item_market_rate && errors.Item_market_rate.message}
              </small>
            </div>

            <div className="w-full max-w-xs">
              <label className="text-sm text-gray-600 font-medium">
                Effective Date
              </label>
              <input
                {...register("Effective_Date",{required: "Date is required"})}
                className="-mt-1 h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                type="date"
              />
                 <small className="text-red-500">
                {errors?.Effective_Date && errors.Effective_Date.message}
              </small>
            </div>
            <div className="w-full max-w-xs mr-4">
            <label className="text-sm text-gray-600 font-medium">Remark</label>
            <input
              {...register("Rate_Change_Remark", {required: "Remark is required",})}
              className="-mt-1 h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
              type="text"
            />
               <small className="text-red-500">
                {errors?.Rate_Change_Remark && errors.Rate_Change_Remark.message}
              </small>
          </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row items-end justify-between">
         
          <button className="py-[12px] px-6 lg:px-8 text-sm rounded-md bg-blue-500 text-white font-medium">
            Save
          </button>
        </div>
      </form>

      <div className="mt-16">
        <h2 className="font-bold text-lg text-gray-600">Sales Rate History</h2>
        <div className="mt-1 h-[500px] w-full overflow-x-scroll">
          <DataGrid
            columns={salesRateHeader}
            key={sales.length}
            rows={sales}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </div>

      <div className="mt-10">
        <h2 className="font-bold text-lg text-gray-600">Cost Rate History</h2>
        <div className="mt-1 h-[500px] w-full overflow-x-scroll">
          <DataGrid
            columns={salesRateHeader}
            rows={cost}
            key={cost?.length}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default RateHistory;
