
import Select from 'react-select';
import { GetProductMasterData} from '../store/productAtom';




function PlanFeeAccountSelect(props) {
  const masterData = GetProductMasterData();

  const customOption = masterData[5]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })

    const customStyles = {
        control: base => ({
          ...base,
          height: 54,
          backgroundColor:'#F1F1F1',
          width:480,
        })
      }


    const handleChange = (value) => {
      props.onPlanFeeAccountSelect(value)
      };


    return ( 
        <>
   
         <Select  defaultValue={{label: props?.defaultVal}}  key={props?.defaultVal} styles={customStyles} onChange={handleChange} options={customOption} />


        </>
     );
}

export default PlanFeeAccountSelect;
