import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import LogicContext from "../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
import SelectSelect from "./StatusSelect";
import { FileUploader } from "react-drag-drop-files";
import { settingsService } from "../../services/settingsService";
import moment from "moment";
import { GetProductCatFeatureAtom, SetProductFeatureEditAtom, GetProductFeatureEditAtom

} from "../../store/productCategoryFeatureAtom";
import ProductFeatureSelect from "./ProductFeatureSelect";
import SpecialImgSelect from "./SpecialImgSelect";
import VisibleToggle from "./VisibleToggle";



const BASE_WIDTH_WEB = 320;
const BASE_HEIGHT_WEB = 320;



function AddProductFeature() {
    const navigate = useNavigate();
      const { pathname } = useLocation();
      var url = pathname.split("/");
      const currModuleId = url[1];
      const currModuleName = url[2];
      const currUserMenu = url[3];
    const myPromiseModal = createModal(GeneralModal);
    const dataAtomStore = GetProductCatFeatureAtom();
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
    const logicContext = useContext(LogicContext);
    
    const [webUrl, setWebUrl] = useState('');
    const setEditData = SetProductFeatureEditAtom();
    const getEditData = GetProductFeatureEditAtom();
    const [webFile, setWebFile] = useState(null);
    const [imageWebSource, setWebImageSource] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
    const [textColor, setTextColor] = useState('#fff');
    const [backgroundColor, setBackgroundColor] = useState('#fff');
    const [tempWebWidth, setWebTempWidth] = useState(0);
  const [tempWebHeight, setWebTempHeight] = useState(0);
  



    useEffect(() => {
        // setValue('feature_status_id', 1);
        // setValue('app_visible_id', 1);
        // setValue('web_visible_id', 1);
    

        if (getEditData?.Action_URL_Id >= 0) {
            setValue('Action_URL_Id', getEditData.Action_URL_Id);
            setValue('product_id', getEditData.Product_Id);
            setValue('name', getEditData.Product_Name);
            setValue('image_url', getEditData.Image_URL);
        setValue('Image_Id', getEditData?.Special_Image_Id);

            setValue('background_color', getEditData.Background_Color);
            setValue('Text_Color', getEditData.Text_Color);
            setValue('display_order', getEditData.Display_Order);
            setValue('status_id', getEditData.Status_Id);
            setValue('description', getEditData.Product_Description);

            setBackgroundColor(getEditData.Background_Color);
            setTextColor(getEditData.Text_Color);
            setWebUrl(getEditData?.Action_URL_Web);
            setWebImageSource(getEditData.Image_URL);
          
        }

        return () => {
            setEditData({});
        }
    }, []);



    const onSubmit = async () => {
        const formValues = getValues();
        if (!imageWebSource || !imageWebSource) {
            return logicContext.showToast('Please upload WEB & Mobile images', 'error');
        }


        //  Start edit

        if (getEditData?.Action_URL_Id >= 0) {
            await myPromiseModal({
                title: "Update record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: '' }).then((res) => {
                if (res.decision === true) {

                    const formData = new FormData();
                
                    formData.append('file', webFile);
                 
                    var formObject = JSON.stringify({ ...formValues, option: 2 });
                    formData.append('formObject', formObject);
                    settingsService.submitNewProductFeature(formData).then(res => {
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            navigate(-1);
                        }
                    });
                }
            });

            // end Edit
        } else {

            //  New record
            await myPromiseModal({
                title: "Save record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: '' }).then((res) => {
                if (res.decision === true) {

                    const formData = new FormData();
                 
                    formData.append('file', webFile);
                    var formObject = JSON.stringify({ ...formValues, option: 1});
                    formData.append('formObject', formObject);
                    settingsService.submitNewProductFeature(formData).then(res => {
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            reset();
                            navigate(-1);
                        }
                    });
                }
            });

            // end new record

        }



    }


    function formError(error) {
        logicContext.showToast("Form error", "error");
    }


    const onStatusSelect = (eveData) => {
        setValue('status_id', eveData.value);
    }


    const onWebImageLoad = (event) => {
        const imageWidth = event?.target?.naturalWidth;
        const imageHeight = event?.target?.naturalHeight;
        setWebTempHeight(imageHeight);
        setWebTempWidth(imageWidth);
        if (imageHeight !== BASE_HEIGHT_WEB){
          return logicContext.showToast(`Image height of ${imageHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT_WEB}h and ${ BASE_WIDTH_WEB}w`, 'error');
        }else if(imageWidth !== BASE_WIDTH_WEB){
          return logicContext.showToast(`Image width of ${imageWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT_WEB}h and ${BASE_WIDTH_WEB}w`, 'error');
        }
    }



    const handleWebImageChange = (webFile) => {
        setWebFile(webFile);
        if (webFile) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setWebImageSource(reader.result);
            });

            reader.readAsDataURL(webFile);
        }
    };


    const onFeatureSelect = (data) => {
        setValue('Action_URL_Id', data?.Action_URL_Id);
        setWebUrl(data?.Action_URL_Web);

    }


    const onSpecialImageSelect = (data) => {
        setValue('Image_Id', data?.Image_Id);
      

    }

    const onBackgroundColorChange = (event) => {
      const selectedColor = event.target.value; 
      setValue('background_color',  selectedColor);
      setBackgroundColor(selectedColor);
    };

    const onTextColorChange = (event) => {
      const selectedColor = event.target.value; 
      setValue('Text_Color',  selectedColor);
      setTextColor(selectedColor);
    };

    const onShowOnWeb = (val) => {
      setValue('Show_On_Web_Menu_Id',  val);
    
    };



    return (
        <>
         <div className="jumbotron bg-light">
    
  <div className="flex justify-between items-end">

    <h6 className="text-lg mb-3 font-bold">Add New Product Feature</h6>
  
    <div className="row" >
   

          <div className="col" >
          <img className="m-1 rounded-full" onLoad={(e) => onWebImageLoad(e)} src={imageWebSource} alt="" width="100px" /> <br/>
         
          </div>
        </div>
  </div>


  <form onSubmit={handleSubmit(onSubmit, formError)}>
    <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <>   
        <label className=" text-sm "><span className="text-danger font-bold" >*</span>  Feature Name:</label>
        <input
          className="bg-inputColor h-[6vh] pl-3 w-full"
          placeholder="enter Product feature name"
          type="text"
          name="name"
          {...register("name", {
            required: "Name is required",
          })}
        />
        <small className="text-red-500">
          {Boolean(errors["name"]?.message) && String(errors["name"]?.message)}
        </small>
        <br/>
        
        </>
        <>
        <label className=" mt-3 text-sm "><span className="text-danger font-bold" >*</span>  Description:</label>
        <textarea
          className="bg-inputColor h-[6vh] pl-3 w-full"
          placeholder="enter description feature name"
          cols="10"
          // rows="10"
          type="text"
          name="description"
          {...register("description", {
            required: "Description is required",
          })}
        />
        <small className="text-red-500">
          {Boolean(errors["description"]?.message) && String(errors["description"]?.message)}
        </small>
        </>
        <br/>
        
        {/* product select */}  
        <label className="mt-2 text-sm ">Product Action: <span className="text-danger font-bold" >*</span> 
        {/* navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`); */}
        </label> <button type="button" onClick={ ()=>  window.open(`/${currModuleId}/${currModuleName}/product-feature-action-url/add-new`, '_blank')} className="btn-primary p-1 mb-1" >New URL</button>
        <ProductFeatureSelect onFeatureSelect={onFeatureSelect} defaultVal={getEditData?.Product_Name} list={dataAtomStore.action_url_info}
        {...register("Action_URL_Id", {
          required: "Product action is required",
        })}
         />
            <small className="text-red-500">
          {Boolean(errors["Action_URL_Id"]?.message) && String(errors["Action_URL_Id"]?.message)}
        </small>
        <br/>
        <span>
        <label className=" text-sm ">Web Url:</label>
          <span className="text-primary">{webUrl}</span>
          {/* <VisibleToggle visible={getEditData?.App_Visible_Id} onVisibleChange={onWebVisible} /> */}
        </span>

        <br/>
        
        {/* product select */}
        <label className="mt-2 text-sm ">Special Image: <span className="text-danger font-bold" >*</span> </label>
        <SpecialImgSelect onFeatureSelect={onSpecialImageSelect} defaultVal={getEditData?.Special_Image_Name} list={dataAtomStore.special_image_info}
        {...register("Image_Id", {
          // required: "Special image is required",
        })}
         />
            <small className="text-red-500">
          {Boolean(errors["Image_Id"]?.message) && String(errors["Image_Id"]?.message)}
        </small>
        <br/>

      </div>

      <div>
        {/* web image */}
      <span className="p-3 mb-5" >
      <label className=" text-sm "><span className="text-danger font-bold" >*</span>  Upload Web Image:</label>
        <FileUploader
          className="uploader"
          id="uploader"
          minSize={0.1}
          maxSize={3}
          placeHolder="example111"
          handleChange={handleWebImageChange}
          name="file"
          types={fileTypes}
          multiple={false}
        />
      </span>

{/* Mobile image */}
          <span className="p-3  mt-5" >
       
          <div>
              <label className="mt-3" for="favcolor">Select Background color:</label> <br/>
              <input  onChange={onBackgroundColorChange} type="color" id="favcolor" 
                style={{ width: '50px',  }} value={backgroundColor} />
            </div>

            <br/>
       
          <div>
              <label className="mt-3" for="text_color">Text Color:</label> <br/>
              <input  onChange={onTextColorChange} type="color" id="text_color" 
                style={{ width: '50px',  }} value={textColor} />
            </div>
          </span>
          {/* Color picker end */}
          
            
            <div className="font-bold"> Show On Web - <span className="text-primary" > 
              </span> <VisibleToggle visible={getEditData?.Show_On_Web_Menu_Id}  onVisibleChange={onShowOnWeb} />
                </div>
      
      </div>

 
      <div>
        <label className="mt-2 text-sm ">Slide Order:</label>
        <input
          className="bg-inputColor h-[6vh] pl-3 w-full"
          placeholder="enter slide order"
          type="number"
          name="display order"
          {...register("display_order", {
            required: "Display order is required",
          })}
        />
        <small className="text-red-500">
          {Boolean(errors["display_order"]?.message) && String(errors["display_order"]?.message)}
        </small>
      </div>
      <div>
        <label className=" text-sm  ">Status:</label>
        <SelectSelect defaultVal={getEditData?.Status_Id} onStatusSelect={onStatusSelect} />
      </div>
    </section>
    <div className="flex justify-end">
      <button
        type="submit"
        className="rounded-lg px-10 hover:bg-sky-700 my-3 py-2 bg-blue-500 text-blue-100"
      >
        Submit
      </button>
    </div>
  </form>
</div>
        </>
    );
}

export default AddProductFeature;





