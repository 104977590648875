import { TableBody, TableCell } from "@material-ui/core";
import { TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { itemsService } from "../services/items.service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function ItemStockInformation(props) {
  const [stockList, setStockList] = useState([]);

  useEffect(() => {
    if (props?.Item_Id) {
      showStockInformation();
    }
  }, [props?.Item_Id]);

  function showStockInformation() {
    // if(stockList.length < 1){
    itemsService.getItemStockInformation(props?.Item_Id).then((res) => {
      console.log('stock information ', res?.data);
      if (res?.data !== undefined) {
        setStockList(res.data);
      }
    });
    // }
  }

  function stockAtHand() {
    let total = 0;
    stockList.map((element) => {
      total += element.Stock_In_Hand;
    });
    return total;
  }

  function committedStock() {
    let total = 0;
    stockList.map((element) => {
      total += element.Commited_Stock;
    });
    return total;
  }

  function availableStock() {
    let total = 0;
    stockList.map((element) => {
      total += element.Available_For_Sale;
    });
    return total;
  }

  function stockPrice() {
    let total = 0;
    stockList.map((element) => {
      total += element.Stock_Price;
    });
    return total;
  }

  return (
    <>
      <div className="border-[1px] border-gray-200 rounded-md bg-white overflow-hidden @container">
        <Accordion className="">
          <AccordionSummary
            onClick={showStockInformation}
            style={{ backgroundColor: "#F1F1F1" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="text-xl font-semibold text-gray-600">
              View Stock Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="mt-2">
                {/* STOCK INFORMATION  */}
                <div className="px-4 py-2 flex flex-col space-y-2 @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0">
                  <span className="text-lg text-gray-600 font-bold mr-2">
                    Stock Information
                  </span>
                  <div className="flex flex-row items-center space-x-2">
                    <button className="py-2.5 px-3 text-sm rounded-md bg-white text-blue-500 border-[1px] border-blue-500 font-medium focus:outline-none hover:text-blue-500/80 hover:border-blue-500/80 transition-all duration-200">
                      Accounting Stock
                    </button>
                    <button className="py-2.5 px-3 text-sm rounded-md bg-blue-500 text-white border-[1px] border-blue-50 font-medium focus:outline-none hover:bg-blue-500/80 transition-all duration-200">
                      Physical Stock
                    </button>
                  </div>
                </div>
                <div className=" mx-3">
                  <TableContainer className="mt-3 table_parent ">
                    <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                      <TableHead
                        style={{ backgroundColor: "#F1F1F1", border: "0" }}
                      >
                        <TableRow>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Warehouse</span>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Stock in Hand</span>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Committed Stock</span>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Available for Sale</span>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Stock Price</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stockList.map((stock, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                <span> {stock.Warehouse_Name} </span>
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {formatCustomNumber(stock.Stock_In_Hand)}{" "}
                                </span>{" "}
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {formatCustomNumber(stock.Commited_Stock)}{" "}
                                </span>{" "}
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {formatCustomNumber(stock.Available_For_Sale)}{" "}
                                </span>
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {formatCustomNumber(stock.Stock_Price)}{" "}
                                </span>{" "}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <span className="font-weight-bold">
                              {" "}
                              Grand Total{" "}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <span className="font-weight-bold">
                              {formatCustomNumber(stockAtHand())}{" "}
                            </span>{" "}
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <span className="font-weight-bold">
                              {formatCustomNumber(committedStock())}{" "}
                            </span>{" "}
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <span className="font-weight-bold">
                              {formatCustomNumber(availableStock())}{" "}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <span className="font-weight-bold">
                              {formatCustomNumber(stockPrice())}{" "}
                            </span>{" "}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default ItemStockInformation;
