import { useEffect } from 'react';
import Select from 'react-select';
import { GetProductFeature } from '../../store/itemGroupAtom';


function AddItemFormGroupSelect(props) {
 


  useEffect(() => {
    console.info('def ', props?.defaultVal);

  }, [props?.defaultVal]);


  const customOption = props?.list?.map((item) => {
    return ({ ...item, label: `${item.Name}` });
  });


  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: 'white',
      // zIndex: 9999,
      width: 250,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    placeholder: styles => ({ ...styles, display : 'none', backgroundColor: '' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, backgroundColor: '', color: "black" }),
    menuPortal: styles => ({ ...styles, zIndex: 99999, backgroundColor: '#fff' })
  }

  return (
    <>
      <div style={{ width: '300px' }}>
        <Select menuPlacement="auto"
        placeholder="Select one"
          menuPosition="fixed" defaultValue={{label: props?.defaultVal }}
          onChange={(e) => props?.onGroupSelect(e)} options={customOption} styles={customStyles} />
      </div>






    </>
  );
}

export default AddItemFormGroupSelect;
