
import Select from 'react-select';
import { GetProductMasterData} from '../store/productAtom';




function PlanAccountSelect(props) {
  const masterData = GetProductMasterData();

  const customOption = masterData[4]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })

    const customStyles = {
        control: base => ({
          ...base,
          height: 54,
          backgroundColor:'#F1F1F1',
          width:480,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }
    // const customStyles = {
    //   control: base => ({
    //     ...base,
    //     height: 30,
    //     width:480,
   
    //   }),
    //   indicatorSeparator:styles => ({ ...styles, display: 'none' }),
    //   indicatorsContainer:styles => ({ ...styles, display: 'none' }),
    // }


    const handleChange = (value) => {
      props.onPlanAccountSelect(value)
      };


    return ( 
        <>
   
         <Select defaultValue={{label: props?.defaultVal}} key={props?.defaultVal}
           styles={customStyles} onChange={handleChange} options={customOption} />


        </>
     );
}

export default PlanAccountSelect;
