import { useEffect } from "react";
import Select from "react-select";

function ImageSlotSelection(props) {
  useEffect(() => {}, [props?.defaultVal]);

  const customOption = props?.slot_info?.map((item) => {
    return { ...item, label: `${item.Name}` };
  });

  return (
    <>
      {/* <div className="w-full mb-[2px]">
        <select
          defaultValue={{ label: props?.defaultVal }}
          onChange={props?.onSlotSelection}
          className="h-11 w-full ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md"
        >
          <option disabled selected className="">
            {props?.defaultVal}
          </option>
          {customOption?.map((option, index) => (
            <option
              key={props?.defaultVal}
              value={option?.value}
              className="hover:bg-white"
            >
              {option?.Name}
            </option>
          ))}
        </select>
      </div> */}
      <Select
        placeholder="Select product sharing slot "
        defaultValue={{ label: props.defaultVal }}
        onChange={props.onSlotSelection}
        options={customOption}
        className="text-md"
      />
    </>
  );
}

export default ImageSlotSelection;
