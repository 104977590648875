import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useRef } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const GeneralModalTimer = ({
  isOpen,
  onResolve,
  onReject,
  title,
  body,
  showInput,
  placeHolder,
}) => {
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [timeValue, setTimeValue] = useState("");

  // Event handlers to update state
  const handleDateChange = (e) => {
    setDateValue(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTimeValue(e.target.value);
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onResolve(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className=" text-center bg-gray-200"
        >
          <span className="font-weight-bold ">{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center font-weight-bold"
          >
            {body}
          </DialogContentText>
          {showInput && (
            <TextareaAutosize
              className="mt-3 bg-light"
              aria-label="minimum height"
              minRows={3}
              onChange={(ev) => setMessage(ev.target.value)}
              placeholder={placeHolder}
              style={{ width: 300 }}
            />
          )}

          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked={isChecked}
              onChange={handleChange}
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1">
              Send later. (
              <small className="text-danger">
                You will need to specify exact date and time.
              </small>
              )
            </label>
          </div>

          {isChecked && (
            <div className="col">
              <div className="form-group">
                <label htmlFor="dateInput">Date</label>
                <input
                  id="dateInput"
                  className="form-control w-50"
                  type="date"
                  value={dateValue}
                  onChange={handleDateChange}
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="timeInput">Time</label>
                <input
                  id="timeInput"
                  className="form-control w-50"
                  type="time"
                  value={timeValue}
                  onChange={handleTimeChange}
                ></input>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className=" text-center bg-gray-200">
          <Button
            color={"error"}
            variant="outlined"
            onClick={() => onResolve({ decision: false, message: message })}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            variant="contained"
            onClick={() =>
              onResolve({
                decision: true,
                message: message,
                dateValue,
                timeValue,
              })
            }
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralModalTimer;
