/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import underscore from "underscore";
import {  useRecoilValue } from "recoil";
import { useEffect } from "react";
import { editCustomerAtom } from "../store/customerAtom";
import "./AddCustomerAddress.css";
import GeneralSelect from "./GeneralSelect";

function AddCustomerAddress(props) {
  var customerToEdit = useRecoilValue(editCustomerAtom);

  const [bill_country, setbill_country] = useState({});
  const [bill_state, setbill_state] = useState({});
  const [bill_area, setbill_area] = useState({});
  

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const onErrors = (errors) => console.error(errors);


  const [stateDisplay, setStateDisplay] = useState([]);
  const [stateDisplay2, setStateDisplay2] = useState([]);
  const [displayArea, setDisplayArea] = useState([]);
  const [displayArea2, setDisplayArea2] = useState([]);
  const { masterData } = props;

  useEffect(() => {
    let defaultValues = {};
    if(customerToEdit?.Customer_Id){
      setValue("Billing_Address1", customerToEdit.Billing_Address1);
      setValue("Billing_Address2", customerToEdit.Billing_Address2);
      setValue("Billing_Attention", customerToEdit.Billing_Attention);
      setValue("Shipping_Attention", customerToEdit.Shipping_Attention);
      setValue("Billing_Zip_Code", customerToEdit.Billing_Zip_Code);
      setValue("Shipping_Zip_Code", customerToEdit.Shipping_Zip_Code);
      setValue("Shipping_Address1", customerToEdit.Shipping_Address1);
      setValue("Shipping_Address2", customerToEdit.Shipping_Address2);
      setValue("Billing_Area_Id", customerToEdit.Billing_Area_Id);
      setValue("Shipping_Area_Id", customerToEdit.Shipping_Area_Id);
      setValue("Shipping_Fax_Number", customerToEdit.Shipping_Fax_Number);
      setValue("Billing_Fax_Number", customerToEdit.Billing_Fax_Number);

    }

    // defaultValues.Billing_Attention = customerToEdit?.Billing_Attention;
    // defaultValues.Shipping_Attention = customerToEdit?.Shipping_Attention;
    // defaultValues.Billing_Zip_Code = customerToEdit?.Billing_Zip_Code;
    // defaultValues.Shipping_Zip_Code = customerToEdit?.Shipping_Zip_Code;
    // defaultValues.Shipping_Address1 = customerToEdit?.Shipping_Address1;
    // defaultValues.Shipping_Address2 = customerToEdit?.Shipping_Address2;
    // defaultValues.Billing_Area_Id = customerToEdit?.Billing_Area_Id;
    // defaultValues.Shipping_City_Id = customerToEdit?.Shipping_City_Id;
    // defaultValues.Shipping_Fax_Number = customerToEdit?.Shipping_Fax_Number;
    // defaultValues.Billing_Fax_Number = customerToEdit?.Billing_Fax_Number;
    var temBillCountry = {
      Code: customerToEdit?.Billing_Country_Id,
      Name: customerToEdit?.Billing_Country_Name,
    };
    var temBillState = {
      Code: customerToEdit?.Billing_City_Id,
      Name: customerToEdit?.Billing_City_Name,
    };
    var temBillArea = {
      Code: customerToEdit?.Billing_Area_Id,
      Name: customerToEdit?.Billing_Area_Name,
    };
    var temSipCountry = {
      Code: customerToEdit?.Shipping_Country_Id,
      Name: customerToEdit?.Shipping_Country_Name,
    };
    var temShipState = {
      Code: customerToEdit?.Shipping_City_Id,
      Name: customerToEdit?.Shipping_City_Name,
    };
    var temShipArea = {
      Code: customerToEdit?.Shipping_Area_Id,
      Name: customerToEdit?.Shipping_Area_Name,
    };

    
  }, []);

  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");

  var countryList = masterDataGroup[1];
  var stateList = masterDataGroup[2];
  var countryList2 = masterDataGroup[1];
  var stateList2 = masterDataGroup[2];
  var billingAreaList = masterDataGroup[3];



  const onSubmit = (formData) => {
    const addressForm = getValues();
    props.submitSection(addressForm);
  };


  const selectedBillCountry = (selection) => {
    var temp = stateList.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setStateDisplay(temp);
  };


  const onSelectBillingState = (selection) => {
    var temp = billingAreaList.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setDisplayArea(temp);
  };


  const onShippingCountrySelect = (selection) => {
    var temp = stateList2.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setStateDisplay2(temp);
  };


  const onShippingStateSelect = (selection) => {
 
    var temp = billingAreaList.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setDisplayArea2(temp);
  };


  const obBillingAreaSelect = (selection) => {
    // setbill_area(selection);
    setValue("Billing_Area_Id",selection.Code);
  };

  
  const onShippingAreaSelect = (selection) => {
    setValue("Shipping_Area_Id", selection.Code);

  };

  function billingAddressAsShippingAddress(ev) {
    if (ev.target.checked) {
      var currentdata = getValues();
      setValue("Shipping_Address1", currentdata.Billing_Address1);
      setValue("Shipping_Address2", currentdata.Billing_Address2);
      setValue("Shipping_Attention", currentdata.Billing_Attention);
      setValue("Shipping_Zip_Code", currentdata.Billing_Zip_Code);
      setValue("Shipping_Fax_Number", currentdata.Billing_Fax_Number);
      setValue("Shipping_Area_Id", currentdata.Billing_Area_Id);

    
    } else {
      setValue("Shipping_Address1", "");
      setValue("Shipping_Address2", "");
      setValue("Shipping_Attention", "");
      setValue("Shipping_Zip_Code", "");
      setValue("Shipping_Fax_Number", "");
      setValue("Shipping_Area_Id", "");
      // setship_country({})
      // setship_state({})
      // setship_area({})
    }
  }




  return (
    
    <section className=" w-full max-w-6xl  mt-3 max-auto ">


      <form onSubmit={handleSubmit(onSubmit, onErrors)} className="mb-5">
        <section className=" w-full max-w-6xl max-auto ">
          <section className="  lg:flex  ">
            {/* BILLING ADDRESS  */}

            <section className="mt-2 ">
              <p className="font-bold text-sm ml-2"> Billing Address </p>

              <div className="md:flex  lg:flex ">
                <label className="">
                  <span className="text-sm text-filterTextColor">
                    Attention
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[80vw] h-[6vh] pl-3 md:w-[30vw] lg:w-[17vw] h-6 xl:h-[6vh] "
                    placeholder="enter attention"
                    name="attention"
                    {...register("Billing_Attention", {
                      // required: "Attention is required",
                    })}
                  />
                  <br></br>
                  <small className="text-danger">
                    {errors?.billing_attention &&
                      errors.billing_attention.message}
                  </small>
                </label>

                <label className=" md:ml-[10vw]  lg:ml-[2vw]  ">
                  <span className="text-sm text-filterTextColor">
                    Address 1
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[80vw] h-[6vh] pl-3 md:w-[30vw] lg:w-[17vw] h-[6vh] xl:h-[6vh] "
                    placeholder="street 1"
                    name="Billing_Address1"
                    {...register("Billing_Address1")}
                  />{" "}
                  <br></br>
                </label>
              </div>
              {/* /country */}
              <div className="md:flex  lg:flex ">
                <label className="">
                  <span className="text-sm text-filterTextColor">
                    {" "}
                    Country/Region
                  </span>
                  <br></br>
                      <GeneralSelect list={countryList} defaultVal={customerToEdit?.Billing_Country_Name}
                       onOptionSelect={selectedBillCountry} />
                </label>

                <label className="md:ml-[10vw]  lg:ml-[2vw]">
                  <span className="text-sm text-filterTextColor">
                    Address 2
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="street "
                    name="billing_address2"
                    {...register("Billing_Address2", {
                      // required: "Billing 1 address is required",
                    })}
                  />
                </label>
              </div>

              <div className="md:flex   lg:flex">
                <label className="">
                  <span className="text-sm text-filterTextColor">State</span>
                  <br></br>
                  <GeneralSelect list={stateDisplay} defaultVal={customerToEdit?.Billing_City_Name}
                       onOptionSelect={onSelectBillingState} />
                 
                </label>
                <label className="md:ml-[10vw]  lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">Zip Code</span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="enter zip code "
                    name="zip_code_1"
                    {...register("Billing_Zip_Code", {
                      // required: "enter billing zip code",
                    })}
                  />
                  <br></br>
                  <small className="text-danger">
                    {errors?.billing_zip_code &&
                      errors.billing_zip_code.message}
                  </small>
                </label>
              </div>

              <div className="md:flex lg:flex ">
                <label className="">
                  <span className="text-sm text-filterTextColor">Area</span>
                  <br></br>
                  <GeneralSelect list={displayArea} defaultVal={customerToEdit?.Billing_Area_Name}
                       onOptionSelect={obBillingAreaSelect} />

                </label>

                <label className="md:ml-[10vw]  lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">Fax</span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="enter fax"
                    {...register("Billing_Fax_Number")}
                  />
                </label>
              </div>

              <label className="flex  ">
                <input
                  type="checkbox"
                  className="bg-inputColor  "
                  placeholder="enter fax"
                  onChange={billingAddressAsShippingAddress}
                />
                <p className="text-sm text-filterTextColor ml-2">
                  Billing address same as shipping address ?
                </p>
              </label>
            </section>
            {/* 
          SHIPPLING ADDRESS */}

            <section className="mt-2 lg:ml-[3vw]">
              <p className="font-bold text-sm "> Shipping Address </p>

              <div className="md:flex   lg:flex">
                <label className="">
                  <span className="text-sm text-filterTextColor">
                    Attention
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="enter attention"
                    {...register("Shipping_Attention")}
                  />
                </label>

                <label className="md:ml-[10vw]  lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">
                    Address 1
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="street 1"
                    {...register("Shipping_Address1")}
                  />
                </label>
              </div>

              <div className="md:flex   lg:flex ">
                <label className="">
                  <span className="text-sm text-filterTextColor">
                    Country/Region
                  </span>
                  <br></br>
                  <GeneralSelect list={countryList2} defaultVal={customerToEdit?.Shipping_Country_Name}
                       onOptionSelect={onShippingCountrySelect} />
                </label>

                <label className=" md:ml-[10vw]  lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">
                    Address 2
                  </span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="street 2"
                    name="Shipping_Address2"
                    {...register("Shipping_Address2")}
                  />
                </label>
              </div>

              <div className="md:flex  lg:flex">
                <label className="">
                  <span className="text-sm text-filterTextColor">State</span>
                  <br></br>
                  <GeneralSelect list={stateDisplay2} defaultVal={customerToEdit?.Shipping_City_Name}
                       onOptionSelect={onShippingStateSelect} />

             
                </label>
                <label className=" md:ml-[10vw] lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">Zip Code</span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="enter zip code "
                    name="Shipping_Zip_Code"
                    {...register("Shipping_Zip_Code")}
                  />
                </label>
              </div>

              <div className="md:flex lg:flex">
                <label className="">
                  <span className="text-sm text-filterTextColor">Area</span>
                  <br></br>
                  <GeneralSelect list={displayArea2} defaultVal={customerToEdit?.Shipping_Area_Name}
                       onOptionSelect={onShippingAreaSelect} />
                </label>

                <label className="md:ml-[10vw] lg:ml-[2vw] ">
                  <span className="text-sm text-filterTextColor">Fax</span>
                  <br></br>
                  <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[17vw] pl-3"
                    placeholder="enter fax"
                    name="shipping_fax_Number"
                    {...register("Shipping_Fax_Number")}
                  />
                </label>
              </div>
            </section>
          </section>
        </section>

        <div className="flex justify-end mr-5 md:ml-[10vw] lg:hidden">
          <button
            type="submit"
            className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
          >
            Save
          </button>
        </div>

        {/* BUTTON DESKTOP */}
        <div className="hidden lg:flex justify-start">
          <button
            type="submit"
            className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100"
          >
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddCustomerAddress;
