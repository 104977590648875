import React, { useEffect, useState, useContext } from "react";
import InvoiceItemSearchItems from "../InvoiceItemSearch";
import plus from "../../assets/Plus.svg";
import LogicContext from "../../Context/LogicContext";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

function AddInvoiceTable(props) {
  const [itemTableList, setItemTableList] =  useState([]);
  const [tempSelection, setTempSelection] =  useState({});
  const [quantity, setQuantity] =  useState(1);
  const logicContext = useContext(LogicContext);

   const currentSelectedItem = (item)=> {
    setTempSelection({...item,Plan_Sales_Quantity: quantity, Item_Sales_Rate: item?.Sales_Rate});
   
   }


   useEffect(()=> {

    if(props?.defaultTableData?.length > 0){

    let newList = props?.defaultTableData?.map(item => {
      return {...item, Sales_Rate: item?.Item_Sales_Rate }
    });
    setItemTableList(newList);
    setTimeout(() => {
      props?.onSelectionList([...newList])
    }, 2500);
    }

   },[])



   const onQuantityChange = (qty, index)=> {
    const newQty = parseInt(qty)
    if(newQty > 0){
      const currentItem = itemTableList[index];
      const newList = itemTableList?.map((item)=> {
        if(item.Plan_Id === currentItem.Plan_Id){
          return {...item, Plan_Sales_Quantity: newQty}
        }else{
          return item;
        }
       
      });
      setItemTableList([...newList]);
      props?.onSelectionList([...newList])
      
    }else{

      setItemTableList([...itemTableList]);
      return logicContext.showToast("Only positive quantity can be processed", "");

    }
  
    
   
   }

   const AddToTable = ()=> {
    if(tempSelection?.Plan_Id){
      const found = itemTableList.find(element => element.Plan_Id === tempSelection.Plan_Id);
      if(found){
        return logicContext.showToast('Selected item already in selection', 'error');
      }
      setItemTableList((prev)=> {
        return ([...prev, {...tempSelection, Plan_Sales_Quantity: quantity}])
      });
      props?.onSelectionList( [...itemTableList, {...tempSelection, Plan_Sales_Quantity: quantity}]);

    }else{
      return logicContext.showToast('Please select an item', 'info');
    }
  
   }



   function deleteItemFromTable(removedItem){
    const newList = itemTableList.filter((item)=> item.Plan_Id !== removedItem.Plan_Id);
    setItemTableList(newList);
    props?.onSelectionList(newList);

   }



 



  return (
    <section className="mx-2  mt-2 max-auto border-2 py-2 px-2 max-w-6xl text-filterTextColor">
    <section className=" ">
      <section className=" mt-1 right-1   md:  ">
        <section className="">
          <section className="profile_section ">
            <div className="  ">
              <div className="flex justify-between w-full">
                {" "}
                <InvoiceItemSearchItems currentSelectedItem={currentSelectedItem} /> 

                <div  onClick={AddToTable}
                 style={{ borderRadius: "5px" }}
                    className=" btn rounded mt-4  px-3 h-[5.8vh] text-filterTextColor bg-filterColor  ml-1 " >
                    <img src={plus} alt="" />
                  </div>
             
                <div>
                
                </div>
              </div>
            </div>
            {/* end */}

            <section className="mt-3">
      
                   <TableContainer className="mt-3">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
         
             {itemTableList?.map((item,i)=> {
              return(

                <TableRow key={i}
               
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{item?.Plan_Id} </TableCell>
                <TableCell align="left">{item?.Plan_Name} </TableCell>
                <TableCell align="left">{item.Item_Sku} </TableCell>
                <TableCell align="left"> {item?.Plan_Description} </TableCell>
                
                <TableCell align="left"> {" "}
                  <input
                    type="number"
                    defaultValue={item?.Plan_Sales_Quantity}
                    style={{ width: "70px" }}
                    onChange={(e)=> onQuantityChange(e.target.value, i)}
                 
                  />{" "}
                </TableCell>
                <TableCell align="left"> 
                  <CurrencyFormat value={formatCustomNumber(item?.Plan_Sales_Rate)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                   </TableCell>

                <TableCell align="left"> 
                <CurrencyFormat value={formatCustomNumber(item?.Plan_Sales_Rate * item?.Plan_Sales_Quantity)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
               
                 </TableCell>
                <TableCell align="left" >
                  {" "}
                  <DeleteIcon onClick={()=>  deleteItemFromTable(item)}  color="primary" />{" "}
                </TableCell>
              </TableRow>
              )
             })}
              
             
            </TableBody>
          </Table>
        </TableContainer>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
  );
}

export default AddInvoiceTable;
