import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useRef } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const GeneralModal = ({
  isOpen,
  onResolve,
  onReject,
  title,
  body,
  showInput,
  placeHolder,
}) => {
  const [message, setMessage] = useState("");

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onResolve(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className=" text-center bg-gray-200"
        >
          <span className="font-weight-bold ">{title}</span>
        </DialogTitle>
        <DialogContent className="">
          <DialogContentText
            id="alert-dialog-description"
            className="text-center font-weight-bold"
          >
            {body}
          </DialogContentText>
          {showInput && (
            <TextareaAutosize
              className="mt-3 bg-light"
              aria-label="minimum height"
              minRows={3}
              onChange={(ev) => setMessage(ev.target.value)}
              placeholder={placeHolder}
              style={{ width: 300 }}
            />
          )}
        </DialogContent>
        <DialogActions className=" text-center bg-gray-200">
          <Button
            color={"error"}
            variant="outlined"
            onClick={() => onResolve({ decision: false, message: message })}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            variant="contained"
            onClick={() => onResolve({ decision: true, message: message })}
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralModal;
