import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../../../services/items.service';
import {  GetItemImageToEdit, SetItemImageToEdit } from '../../../store/itemAtom';
import { BASE_WIDTH, BASE_HEIGHT } from '../../../helpers/constantsFile';

let noImageTypeError = true;
let noImagesizeError = true;
let sizeIsCorrect = false;

const beforeUpload = (file) => {
  console.log('File1234 ', file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    noImageTypeError =  isJpgOrPng;
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;

    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    noImagesizeError =  isLt2M;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return ;
    }
    return isJpgOrPng && isLt2M;
  };



function PrimaryImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
  const getItemImageToEdit = GetItemImageToEdit();
  const setItemImageToEdit = SetItemImageToEdit();

  useEffect(()=> {
    if(props?.defaultImage){
      setImageUrl(props.defaultImage);
    }
  },[props?.defaultImage]);


  // const onFileChange =  (e) => {
  //   const imgFile =  e?.fileList[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(imgFile?. originFileObj );
  
  //   reader.onload =async  (e) => {
  //     const img = new Image();
  //     img.src = e.target?.result;
  
  //      img.onload = async () => {
  //       console.log("Width:", img.width);
  //       console.log("Height:", img.height);
  //       if(img.width !== BASE_WIDTH ||  img.height !== BASE_HEIGHT){
  //         sizeIsCorrect =false
  //       }else{
  //         sizeIsCorrect = true;
  //       }
  //     };
  //   };
  //   // const imageWidth = e?.target?.naturalWidth;
  //   // const imageHeight = e?.target?.naturalHeight;
  //   // console.log('imageWidth ', imageWidth);
  //   // console.log('imageHeight ', imageHeight);
  //   // setTempHeight(imageHeight);
  //   // setTempWidth(imageWidth);
  //   // if (imageHeight !== BASE_HEIGHT){
  //   //   return logicContext.showToast(`Image height of ${imageHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
  //   // }else if(imageWidth !== BASE_WIDTH){
  //   //   return logicContext.showToast(`Image width of ${imageWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
  //   // }
  // }


  
    const customUpload = ({ file, onSuccess, onError }) => {
    //   console.log('sizeIsCorrect ', sizeIsCorrect);
    //  if(sizeIsCorrect === false ){
    //   window.alert(`the selected image is rejected, please upload image with ${BASE_WIDTH} width and ${BASE_HEIGHT} height!`);
    //   return;
    // }
   
      
      if(noImagesizeError && noImageTypeError){
        const formData = new FormData();
      formData.append('file', file);
          const formObject = {Item_Id: props?.Item_Id, image_id: props?.imageId || 0 ,  option: props?.imageId ? 4 : 1}
      formData.append('formObject', JSON.stringify(formObject));
    
      itemsService.submitItemImage(formData).then(res => {
        onSuccess(res.data);
        const newUrl =  res.data?.file_link;
        setImageUrl(newUrl);
        const newList =  getItemImageToEdit.map((img)=> {
          if(img.Image_Id === props?.Item_Id){
            return {...img, Image_URL : newUrl}
          }else{
            return img;
          }
        });
        setItemImageToEdit(newList);
        })
        .catch(err => {
          onError(err);
          setLoading(false);
          window.alert('Image upload failed!');
        });

      }
      
    };


  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
       
        // onChange={onFileChange}
        beforeUpload={beforeUpload}
        customRequest={customUpload}  // Use manual upload
        multiple={false}
      >
        {imageUrl ? (
          <>
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
         
          </>
          
        ) : (    uploadButton  )}
      </Upload>

      
        </>
     );
}

export default PrimaryImageUpload;