import { useEffect, useState } from "react";

function AddItemTrackingTypeCheckRadioButton(props) {
  const [noneChecked, setNoneChecked] = useState(false);
  const [serialChecked, setSerialChecked] = useState(false);
  const [batchesChecked, setBatchesChecked] = useState(false);

  useEffect(() => {
    switch (props.val) {
      case 1:
        setNoneChecked(true);
        setSerialChecked(false);
        setBatchesChecked(false);
        break;
      case 2:
        setNoneChecked(false);
        setSerialChecked(true);
        setBatchesChecked(false);
        break;
      case 3:
        setNoneChecked(false);
        setSerialChecked(false);
        setBatchesChecked(true);
        break;

      default:
        setNoneChecked(false);
        setSerialChecked(false);
        setBatchesChecked(false);
        break;
    }
  }, [props.val]);

  const noneSelectChange = () => {
    setNoneChecked(true);
    setSerialChecked(false);
    setBatchesChecked(false);
    props.addItemTrackingType(1);
  };

  const serialSelectChange = () => {
    setNoneChecked(false);
    setSerialChecked(true);
    setBatchesChecked(false);
    props.addItemTrackingType(2);
  };

  const batchSelectChange = () => {
    setNoneChecked(false);
    setSerialChecked(false);
    setBatchesChecked(true);
    props.addItemTrackingType(3);
  };

  return (
    <>
      <div className="mt-2 flex text-sm">
        <div className="ml-3 flex flex-row items-center space-x-1.5">
          <input
            checked={noneChecked}
            onChange={noneSelectChange}
            value="1"
            type="radio"
            className="h-3 w-3 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
          />
          <div className="text-xs font-normal text-gray-600">None</div>
        </div>
        <div className="ml-3 flex flex-row items-center space-x-1.5">
          <input
            checked={serialChecked}
            onChange={serialSelectChange}
            value="2"
            type="radio"
            className="h-3 w-3 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
          />
          <div className="text-xs font-normal text-gray-600">
            Track Serial No
          </div>
        </div>
        <div className="ml-3 flex flex-row items-center space-x-1.5">
          <input
            checked={batchesChecked}
            onChange={batchSelectChange}
            value="3"
            type="radio"
            className="h-3 w-3 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
          />

          <div className="text-xs font-normal text-gray-600">Track Batches</div>
        </div>
      </div>
    </>
  );
}

export default AddItemTrackingTypeCheckRadioButton;
