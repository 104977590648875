import { Dropdown } from "react-bootstrap";
import trash from "../../assets/trash.png";
import { Link, useLocation } from "react-router-dom";
import { itemsService } from "../../services/items.service";
import LogicContext from "../../Context/LogicContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import {
  SetTItemList,
  GetItemList,
  SettItemBackupList,
  GetItemBackupList,
  ResetItemToEdit,
} from "../../store/itemAtom";
import underscore from "underscore";
import CsvDownload from "react-json-to-csv";
import ItemPageTable from "./ItemPageTable";
// import "../pages/ItemPage.css";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";


function ItemPage() {
  const logicContext = useContext(LogicContext);
  const resetItemToEdit = ResetItemToEdit();
  const [itemSelect, setItemSelect] = useState([]);
  const setItemListState = SetTItemList();
  const setItemBackupList = SettItemBackupList();
  const itemAtomData = GetItemList();
  const itemMasterList = GetItemBackupList();
  const myPromiseModal = createModal(GeneralModal);


  useEffect(() => {
    document.title = "Item Page";

    resetItemToEdit();
  }, []);

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    if (itemAtomData.item_info.length < 1) {
      itemsService.getItemsList().then((res) => {
        if (res?.data) {
          setItemListState(res?.data);
          setItemBackupList(res?.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemAtomData]);

  function loadItemsFromServer() {
    itemsService .getItemsList().then((res) => {
        if (res?.data?.item_info.length > 0) {
          setItemListState(res?.data);
          setItemBackupList(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }

  function customFilter(value) {
  
    if (value === "All") {
      return setItemListState(itemMasterList);
    }

    if (value === "Item_Id") {
      var temp = underscore.sortBy(itemAtomData.item_info, value);
      var temp2 = temp.reverse();
      setItemListState({itemAtomData, item_info:temp2});
    } else {
      var customFilter = underscore.sortBy(itemAtomData.item_info, value);
      setItemListState({itemAtomData, item_info:customFilter});
    }
  }


  function filterItemReturnable(status) {
    var customFilter = itemMasterList?.item_info.filter(
      (item) => item.Item_Returnable_Name === status
    );
    if (customFilter?.length) {
      setItemListState({itemAtomData, item_info:customFilter});
    } else {
      setItemListState({itemAtomData, item_info:[]});
    }
  }

  function filterByVariety(code) {
    var customFilter = itemMasterList?.item_info.filter(
      (item) => item.Item_Variety_Id === code
    );
    if (customFilter?.length) {
      setItemListState({itemAtomData, item_info:customFilter});
    } else {
      setItemListState({itemAtomData, item_info:[]});
    }
  }

  function filterByCategory(code) {
    var customFilter = itemMasterList?.item_info.filter(
      (item) => item.Item_Category_Id === code
    );
    if (customFilter?.length) {
      setItemListState({itemAtomData, item_info:customFilter});
    } else {
      setItemListState({itemAtomData, item_info:[]});
    }
  }

  function filterIventoryItems(status) {
    var customFilter = itemMasterList?.item_info.filter(
      (item) => item.Item_Tracking_Need_Name === status
    );
    if (customFilter?.length) {
      setItemListState({itemAtomData, item_info:customFilter});
    } else {
      setItemListState({itemAtomData, item_info:[]});
    }
  }

  function selectedItems(itemList) {
    setItemSelect(itemList);
  }

  function setItemToActive() {
    var xmlString = "";
    itemSelect?.map((item) => {
      xmlString += `<a><refno>${item.Item_Id} </refno></a>`;
    });

    itemsService
      .setItemActive(`<root> ${xmlString} </root>`)
      .then((res) => {
        loadItemsFromServer();
      })
      .catch((err) => console.log("err ", err));
  }

  function setItemToInActive() {
    var xmlString = "";
    itemSelect?.map((item) => {
      xmlString += `<a><refno>${item.Item_Id} </refno></a>`;
    });

    itemsService
      .setIteminActive(`<root> ${xmlString} </root>`)
      .then((res) => {
        loadItemsFromServer();
      })
      .catch((err) => console.log("err ", err));
  }

 async  function deleteItems() {
    await myPromiseModal({
      title: "Confirmation",
      body: `${itemSelect?.length} will be deleted!`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        // delete confirmed
        var xmlString = "";
        itemSelect?.map((item) => { xmlString += `<a><refno>${item.Item_Id} </refno></a>` });
    
        itemsService.deleteItems(`<root> ${xmlString} </root>`).then((res) => {
          loadItemsFromServer();
        }) .catch((err) => console.log("err ", err));



        // End delete confirmed
        
      }
    });
 
  }

  return (
    <div className=" ">
      <section className="">
        <section className=" py-4 px-2    md:flex justify-between ">
          <div className="flex">
            <div className=" mt-1 flex  rounded bg-filterColor pr-4 -ml-2 ">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#E5E5E5",
                    border: "none",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "35px",
                  }}
                  id="dropdown-basic"
                >
                  FILTER BY
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => customFilter("All")}>
                    All
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterByVariety(2)}>
                    Composite Items
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterByVariety(1)}>
                    SIngle Items
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterByCategory(1)}>
                    Raw Materials
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterByCategory(2)}>
                    Packing Materials
                  </Dropdown.Item>

                  {/* <Dropdown.Item onClick={() => customFilterByStatus("Active")}>
                  Active Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => customFilterByStatus("Inactive")}>
                  InActive Items
                </Dropdown.Item> */}

                  <Dropdown.Item onClick={() => filterIventoryItems("Yes")}>
                    Inventory Items
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterIventoryItems("No")}>
                    Non Inventory Items
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterItemReturnable("No")}>
                    Non Returnable Items
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => filterItemReturnable("Yes")}>
                    Returnable Items
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => loadItemsFromServer()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
          </div>

          <div className="flex  ">
            {itemSelect.length > 0 ? (
              <div className="flex ">
                <button
                  onClick={() => setItemToActive()}
                  className=" bg-filterColor rounded text-black  text-xs py-[1.8vh] font-[Poppins]  mt-1 mr-1 px-4"
                >
                  ACTIVE
                </button>

                <button
                  onClick={() => setItemToInActive()}
                  className="bg-filterColor mx-2  rounded text-black text-xs py-[1.8vh] font-[Poppins] mt-1  px-4"
                >
                  INACTIVE
                </button>

                <img
                  onClick={() => deleteItems()}
                  className=" bg-filterColor mr-2   px-4 py-[1.8vh] mt-1   rounded text-black"
                  src={trash}
                  alt=""
                />

                {/* <button className=" bg-filterColor rounded text-black text-sm font-[Poppins] py-2 mt-1  px-3">
                  Import
                </button>

                <button className="bg-filterColor mx-2 rounded text-black text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3">
                  Export
                </button> */}
              </div>
            ) : null}

            <Link
              to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-item/${1}`}
            >
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                ADD NEW
              </button>
            </Link>
          </div>
        </section>

        {/*  Table here... */}
        <ItemPageTable selectedItems={selectedItems}></ItemPageTable>
      </section>
    </div>
  );
}

export default ItemPage;
