import { useEffect } from "react";

import { itemsService } from "../../services/items.service";
import {
  SetTItemList,
  GetItemList,
  SettItemBackupList,
  GetItemBackupList,
  ResetItemToEdit,
} from "../../store/itemAtom";
import { useParams } from "react-router-dom";

import { twMerge } from "tailwind-merge";

function ItemDetailsSideScroll(props) {
  const setItemListState = SetTItemList();
  const setItemBackupList = SettItemBackupList();
  const itemListData = GetItemList();
  const itemMasterList = GetItemBackupList();
  const { item_id } = useParams();

  useEffect(() => {
    if (itemListData.item_info.length < 1) {
      itemsService.getItemsList().then((res) => {
        if (res?.data !== undefined) {
          setItemListState(res?.data);
          setItemBackupList(res?.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemListData?.item_info.length]);

  function customFilter(e) {
    const value = e.target.value;
    if (value == 0) {
      return setItemListState(itemMasterList);
    }

    if (value == 1) {
      var customFilter = itemMasterList.filter(
        (item) => item.Item_Variety_Id === 2
      );
      return setItemListState(customFilter);
    }

    if (value == 2) {
      var customFilter = itemMasterList.filter(
        (item) => item.Item_Variety_Id === 1
      );
      return setItemListState(customFilter);
    }

    if (value == 3) {
      var customFilter = itemMasterList.filter(
        (item) => item.Item_Category_Id === 1
      );
      return setItemListState(customFilter);
    }

    if (value == 4) {
      var customFilter = itemMasterList.filter(
        (item) => item.Item_Category_Id === 2
      );
      return setItemListState(customFilter);
    }
  }

  console.log("IDDD ", item_id);

  return (
    <>
      <div
        className="w-full p-2 border-[1px] border-gray-200 rounded-lg overflow-y-scroll h-[500px] scrollbar-thumb-gray-400 scrollbar-thin scrollbar-track-gray-200 pb-[200px]"
        onChange={customFilter}
      >
        {/* <p className="ml-4 mt-4 text-xs font-bold md:text-sm ">Items Information</p> */}

        <select className="w-full max-w-xs mb-4 text-xs text-[#45444B] bg-[#E5E5E5] rounded-md">
          <option value={0} className="bg-filterColor">
            All
          </option>
          <option value={1} className="bg-filterColor">
            Composite Items
          </option>
          <option value={2} className="bg-filterColor">
            SIngle Items
          </option>
          <option value={3} className="bg-filterColor">
            Raw Materials
          </option>
          <option value={4} className="bg-filterColor">
            Packing Materials
          </option>
        </select>

        {itemListData?.item_info.map((item, i) => {
          return (
            <div
              className={twMerge(
                `border-[1px] px-2 py-3 cursor-pointer border-gray-200 ${
                  Number(item_id) === item.Item_Id
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }`
              )}
              key={i}
            >
              <div
                onClick={() => props.viewItemDetails(item.Item_Id)}
                className=" "
              >
                <span className="text-xs font-medium text-gray-600">
                  {item.Item_Name}
                </span>{" "}
                |{" "}
                <span className="text-xs font-medium text-gray-700">
                  {item?.Item_Type_Name}{" "}
                </span>
                <br />
                <div className="text-sm font-medium text-primary">
                  {item?.Item_Variety_Name}{" "}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ItemDetailsSideScroll;
