function DimentionSelect(props) {
  const handleChange = (e) => {
    const selection  = e.target.value;
    props.selectedDimension(selection);
  };

  const createSelectItems = () => {
    let items = [];

        // Default option
        if(props?.defaultVal){
          items.push(
            <option key="default" value="" disabled selected>
              {props?.defaultVal}
            </option>
          );

        }
    

    props?.productDimension?.forEach((item, i) => {
      items.push(
        <option key={i} value={item?.Code}>
          {item.Name}
        </option>
      );
    });
    return items;
  };

  return (
    <>
      <select
        className="h-[40px] text-blue-600 text-sm font-medium outline-none focus:outline-none cursor-pointer rounded-md hover:underline-offset-1"
        onChange={handleChange}
      >
        {createSelectItems()}
      </select>
    </>
  );
}

export default DimentionSelect;
