import React, { useState, useEffect, Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { IoChevronDownOutline } from "react-icons/io5";
// import Select from "react-select";
// import SelectComponent from "./SelectComponent";

function GeneralDropDown(props) {
  const [selected, setSelected] = useState({});
  const [query, setQuery] = useState("");

  const customOption = props?.dropDown?.map((item) => {
    return { ...item, label: `${item?.Name}` };
  });

  const filteredPeople =
    query === ""
      ? props?.dropDown
      : props?.dropDown?.filter((person) =>
          person.Name.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  useEffect(() => {
    if (props?.defaultVal) {
      setSelected({ id: 1, Name: props?.defaultVal });
    }
  }, [props?.customList, props?.defaultVal]);

  const onOptionSelect = (val) => {
    setSelected(val);
    props.optionSelect(val);
  };

  return (
    <>
      <div className="w-full mb-[2px]">
        <Combobox value={selected} onChange={onOptionSelect}>
          <div className="relative">
            <div className="relative w-full">
              <Combobox.Input
                className="w-full h-11 z-10 border-[1px] border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm text-gray-700 focus:ring-1 focus:ring-blue-500"
                displayValue={(item) => item.Name}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute w-fit inset-y-0 right-2 items-center">
                <IoChevronDownOutline
                  className="h-4 w-4 text-gray-500"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 z-30 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-gray-300 ring-black/5 focus:outline-none sm:text-sm">
                {filteredPeople?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredPeople?.map((item) => (
                    <Combobox.Option
                      key={item.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-4 pr-4 ${
                          active ? "bg-gray-100 text-white" : "text-gray-900"
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={` ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {item.Name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute top-2 items-center ${
                                active ? "text-gray-700" : "text-blue-500"
                              }`}
                            >
                              {/* <CheckIcon className="h-4 w-4" /> */}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>

      {/* <div className="mt-5 w-full mb-[2px]">
        <select
          defaultValue={{ label: props?.defaultVal }}
          onChange={(e) => props.optionSelect(e)}
          className="h-11 w-full ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md"
        >
          <option disabled selected className="">
            {props?.defaultVal}
          </option>
          {customOption?.map((option, index) => (
            <option
              key={props?.defaultVal}
              value={option?.value}
              className="text-wrap"
            >
              {option?.Name}
            </option>
          ))}
        </select>
      </div> */}

      {/* <div className="mt-5">
        <Select
          defaultValue={{ label: props?.defaultVal }}
          key={props?.defaultVal}
          onChange={(e) => props.optionSelect(e)}
          options={customOption}
          className="h-11 w-full"
        />
      </div> */}
      {props.manage && (
        <small className="text-center">
          <button
            type="button"
            onClick={() => props.manageList()}
            className=" text-blue-600 text-sm font-medium outline-none focus:outline-none cursor-pointer hover:underline hover:underline-offset-1"
          >
            Add Option
          </button>
        </small>
      )}
    </>
  );
}

export default GeneralDropDown;
