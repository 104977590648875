import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import underscore from "underscore";

function TypeSelect(props) {
  const [goodChecked, setGoodsChecked] = useState(true);
  const [serviceChecked, setServiceChecked] = useState(false);

  useEffect(() => {
    if (props.defaultVal === 1) {
      setGoodsChecked(true);
      setServiceChecked(false);
    } else if (props.defaultVal === 2) {
      setServiceChecked(true);
      setGoodsChecked(false);
    }
  }, [props?.defaultVal]);

  const handleGoodsChange = () => {
    setGoodsChecked(true);
    setServiceChecked(false);
    props.addItemType(1);
  };

  const handleServiceChange = () => {
    setGoodsChecked(false);
    setServiceChecked(true);
    props.addItemType(2);
  };

  return (
    <>
      <div>
        <span className="text-lg font-medium text-gray-700">Type</span>
        <div className="mt-2 flex flex-row items-center space-x-4">
          <div className="flex flex-row items-center space-x-1">
            <input
              type="radio"
              className="h-4 w-4 z-10 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
              value="1"
              checked={goodChecked}
              onChange={handleGoodsChange}
            />
            <div className="text-sm font-medium text-gray-600">Goods</div>
          </div>
          <div className="flex flex-row items-center space-x-1">
            <input
              type="radio"
              className="h-4 w-4 z-10 fill-zinc-100 border-[1px] border-gray-500 rounded-full focus:ring-0 ring-0 cursor-pointer"
              value="2"
              checked={serviceChecked}
              onChange={handleServiceChange}
            />
            <div className="text-sm font-medium text-gray-600">Services</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TypeSelect;
