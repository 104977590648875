import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableContainer } from "@mui/material";
import select from "../assets/selectarrow.png";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import React from "react";
import Paper from "@mui/material/Paper";
import { itemsService } from "../services/items.service";
import { useEffect, useRef, useState } from "react";
import underscore from "underscore";
import up from "../assets/icons/Caret_up.png";
import down from "../assets/icons/Caret_down.png";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../store/transAtom";
import "../components/ItemT.css";
import { GetItemTransactionValue, SetItemTransaction } from "../store/itemAtom";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const tableHeader = [
  {
    field: "Order_Date",
    headerName: "Date",
    width: 100,
    headerClassName: "tableHeader ",
  },
  {
    field: "Order_No",
    headerName: "Order No",
    width: 100,
    headerClassName: "tableHeader ",
  },
  {
    field: "Customer_Name",
    headerName: " Customer Name",
    width: 100,
    headerClassName: "tableHeader",
  },
  {
    field: "Sales_Quantity",
    headerName: "  Qty Sold",
    width: 100,
    headerClassName: "tableHeader",
  },
  {
    field: "Sales_Rate",
    headerName: " Price",
    width: 100,
    headerClassName: "tableHeader",
  },
  {
    field: "Total_Amount",
    headerName: " Total",
    width: 100,
    headerClassName: "tableHeader",
  },
  {
    field: "Order_Status_Name",
    headerName: " Status",
    width: 100,
    headerClassName: "tableHeader",
  },
];

function ItemTransaction(props) {
  const transList = GetItemTransactionValue();
  const setTrans = SetItemTransaction();

  useEffect(() => {
    itemsService.getItemTransaction(props.item_id).then((res) => {
      const historyList = res.data?.map((item, i) => {
        return { ...item, id: i };
      });

      if (historyList.length) {
        setTrans(historyList);
      } else {
        setTrans([]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item_id]);

  return (
    <div
      className="w-full @container"
      id="tabs-home"
      role="tabpanel"
      aria-labelledby="tabs-home-tab"
    >
      <div className="mt-4">
        {/* SEARCH BAR */}
        <div className="flex justify-start  ">
          <div className="relative w-full max-w-sm">
            <input
              className="h-[40px] flex items-center pl-10 pr-4 text-sm placeholder:text-sm placeholder:text-gray-400 w-full bg-white ring-1 ring-gray-300 rounded-full outline-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search"
            />

            <button className="absolute left-3 top-2.5 w-fit text-gray-500 focus:outline-none outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="mt-4 space-y-4 @xs:space-y-0 @xs:flex @xs:flex-row @xs:items-center @xs:space-x-4">
          <div className="" htmlFor="">
            <label className="mr-2">Filter by:</label>
            <select
              style={{ color: "#476EF8" }}
              className="ring-[1px] ring-gray-300 rounded-md"
              name="cars"
              id="cars"
            >
              <optgroup>
                <option value="volvo">Sales Order</option>
              </optgroup>
            </select>
          </div>

          <div htmlFor="">
            <label className="mr-2">Status:</label>
            <select
              style={{ color: "#476EF8" }}
              className="ring-[1px] ring-gray-300 rounded-md"
              name="cars"
              id="cars"
            >
              <optgroup>
                <option value="volvo">All</option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div className="mt-7 w-full h-[500px]">
        <DataGrid
          columns={tableHeader}
          rows={transList}
          components={{
            Pagination: CustomPagination,
          }}
        />
      </div>
    </div>
  );
}

export default ItemTransaction;
