import { useEffect } from "react";
import noImage from "../assets/no_image.jpeg";

function ItemImageDisplay({ imageList }) {
  useEffect(() => {}, [imageList?.length]);

  return (
    <>
      <div class="container mx-auto">
        <div className="h-72 w-full @sm:p-4 bg-white flex items-center justify-center border-[1px] border-gray-200 rounded-xl">
          <img
            src={imageList[0]?.Image_URL || noImage}
            alt="image_1"
            className="h-full w-auto aspect-auto object-contain bg-white"
          />
        </div>
        <div class="mt-8 grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="w-full h-auto p-2 rounded bg-white shadow-md">
            <img
              src={imageList[1]?.Image_URL || noImage}
              alt="image_2"
              className="h-full w-auto object-contain"
            />
          </div>
          <div className="w-full h-auto p-2 rounded bg-white shadow-md">
            <img
              src={imageList[2]?.Image_URL || noImage}
              alt="image_3"
              className="h-full w-auto object-contain"
            />
          </div>
          <div className="w-full h-auto p-2 rounded bg-white shadow-md">
            <img
              src={imageList[3]?.Image_URL || noImage}
              alt="image_4"
              className="h-full w-auto object-contain"
            />
          </div>
          <div className="w-full h-auto p-2 rounded bg-white shadow-md">
            <img
              src={imageList[4]?.Image_URL || noImage}
              alt="image_5"
              className="h-full w-auto object-contain"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemImageDisplay;
