import React, { useEffect } from "react";
import chat from "../assets/chat.png";
import customeravater from "../assets/avaer2.png";
import edit from "../assets/edit.svg";
import canccel from "../assets/x-octagon.svg";
import "../pages/Image.css";
import chart from "../assets/chart.svg";
import "./ItemOverviewPage.css";
import "./ItemOverview.css";

import Text from "antd/lib/typography/Text";
import { useParams } from "react-router-dom";
import ItemStockInformation from "./ItemStockInformation";
import AssociateSection from "./AssociateSection";
import { Grid } from "@mui/material";
import ItemImageDisplay from "./itemImageDisplay";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function ItemOverviewPage(props) {
  const { item_id } = useParams();

  return (
    <>
      <div className="border-[1px] border-gray-200 rounded-lg p-4 @container">
        {/* BEANS SECTION */}

        <div className="w-full @xl:grid @xl:grid-cols-5 @xl:gap-4">
          <div className="mt-3 @xl:col-span-2 ">
            <div className="text-gray-600 font-bold text-xl">
              {props.itemDetails.Item_Name}{" "}
            </div>
            <div className="mt-4 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Item Id</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Id}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Type</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Type_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Category</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Category_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Unit</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Unit_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Group</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Group_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Dimension</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Dimension_Unit_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">SKU</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Sku}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">is Returnable</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Returnable_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Type</div>
              <div className="text-xs font-bold">
                {props.itemDetails.Item_Variety_Name}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2">Type</div>
              <div className="text-xs font-bold">
                {props.itemDetails?.Product_Sharing_Status_Id === 1
                  ? " GrooPool Item "
                  : "Not GrooPool"}
              </div>
            </div>
            <div className="mt-1 flex fle-col items-center text-gray-700">
              <div className="w-[120px] text-sm mr-2"> Stock</div>
              {/* <span className="text-xs font-bold w-30  mt-[0.5vh] "> */}
              <div
                className={`text-xs font-bold px-2 py-1 rounded ${
                  !props.itemDetails?.Item_Stock_Out
                    ? "bg-green-200"
                    : "bg-red-200"
                }`}
              >
                {props.itemDetails.Item_Stock_Out ? "Out of Stock" : "In Stock"}
              </div>
            </div>
          </div>

          <div className="mt-3 @xl:col-span-3">
            <ItemImageDisplay
              key={props.imageList?.length}
              imageList={props.imageList}
            />
          </div>
        </div>
      </div>
      {/* PURCHASE SECTION  */}
      <div className="mt-4 p-4 border-[1px] border-gray-200 bg-white rounded-md">
        {/* PURCHASE SECTION */}

        <div className="grid grid-cols-1 @md:grid-cols-2 gap-4 ">
          <div className="flex-1">
            <h3 className="mt-3 text-base font-bold text-gray-700">
              Purchase Information
            </h3>
            <div className="mt-3 text-xs flex flex-row items-center">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Cost Price
              </div>
              <div className="text-sm font-bold text-gray-600">
                <Text>&#8358;</Text>{" "}
                {formatCustomNumber(props.itemDetails.Cost_Rate)}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center ">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Market Price
              </div>
              <div className="text-sm font-bold text-gray-600">
                <Text>&#8358;</Text>{" "}
                {formatCustomNumber(props.itemDetails.Market_Rate)}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Account{" "}
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Sales_Accounts_Name}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center ">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Description
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Purchase_Description}{" "}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Preferred Vendor
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Preferred_Vendor_Name}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <h3 className="mt-3 text-base font-bold text-gray-700">
              Sales Information
            </h3>
            <div className="mt-3 text-xs flex flex-row items-center ">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Grocedy Price{" "}
              </div>
              <div className="text-sm font-bold text-gray-600">
                <Text>&#8358;</Text>{" "}
                {formatCustomNumber(props.itemDetails.Sales_Rate)}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center ">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Account{" "}
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Sales_Accounts_Name}{" "}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Description
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Sales_Description}{" "}
              </div>
            </div>
            <div className="mt-1 text-xs flex flex-row items-center">
              <div className="w-[110px] font-medium text-gray-600 mr-2">
                Re order level
              </div>
              <div className="text-sm font-bold text-gray-600">
                {props.itemDetails.Reorder_Quantity}{" "}
              </div>
            </div>
          </div>
        </div>

        {/* AASOCIATE  */}
        {props?.itemDetails?.Item_Variety_Id == 2 ? (
          <AssociateSection Item_Id={item_id}></AssociateSection>
        ) : null}
      </div>

      <div className=" mt-4">
        <ItemStockInformation
          Item_Id={props.itemDetails.Item_Id}
        ></ItemStockInformation>
      </div>

      {/* SALES ORDER SUMMARY  */}
      <div className=" mt-4 border-[1px] border-gray-200 p-4 lg:p-6 bg-white rounded-md">
        {/* STOCK INFORMATION  */}
        <div className="text-base font-bold text-gray-600 mb-2">
          Sales Order Summary (in NGN)
        </div>
        <img src={chart} alt="" />
      </div>

      {/* opening stock */}
      <div className=" mt-4 border-[1px] border-gray-200 rounded-md bg-white ">
        {/* Opening Stock  */}
        <div className="mx-4 mt-4">
          <div className="text-sm text-gray-600">
            Opening Stock :{" "}
            <span className="text-lg font-bold text-gray-700">₦2,200.000</span>
          </div>

          <div className="grid grid-cols-1 @md:grid-cols-2 gap-4">
            <div className="flex-1">
              <h3 className="mt-3 text-base font-bold text-gray-700">
                Accounting Stock
              </h3>
              <div className="mt-3 text-xs flex flex-row items-center">
                <div className="w-[110px] font-medium text-gray-600 mr-2">
                  Stock on Hand
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text>16.00
                </div>
              </div>
              <div className="mt-1 text-xs flex flex-row items-center ">
                <div className="w-[110px] font-medium text-gray-600 mr-2 ">
                  Commited Stock
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text> 16.00
                </div>
              </div>
              <div className="mt-1 text-xs flex flex-row items-center ">
                <div className="w-[110px] font-medium text-gray-600 mr-2 ">
                  Available for stock
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text> 16.00
                </div>
              </div>
            </div>
            <div className="flex-1">
              <h3 className="mt-3 text-base font-bold text-gray-700">
                Sales Information
              </h3>
              <div className="mt-3 text-xs flex flex-row items-center">
                <div className="w-[110px] font-medium text-gray-600 mr-2 ">
                  Stock on Hand
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text> 16.00
                </div>
              </div>
              <div className="mt-1 text-xs flex flex-row items-center ">
                <div className="w-[110px] font-medium text-gray-600 mr-2">
                  Commited Stock{" "}
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text>16.00
                </div>
              </div>
              <div className="mt-1 text-xs flex flex-row items-center">
                <div className="w-[110px] font-medium text-gray-600 mr-2">
                  Available for stock
                </div>
                <div className="text-sm font-bold text-gray-600">
                  <Text>&#8358;</Text> 16.00
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 @sm:grid-cols-2 @md:grid-cols-4 gap-3 font-medium text-gray-600">
            <div className="">
              <span className="">To be Shipped</span>{" "}
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="">
              <span className="">To be Received</span>{" "}
              <span className="zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="">
              <span className="">To be Invoiced</span>{" "}
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="">
              <span className="">To be Billed</span>{" "}
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
          </div>
        </div>

        {/* REORDER POINT  */}
        <div className="mt-4">
          {/* STOCK INFORMATION  */}
          <div className="mx-4 reorder-point stock mb-3">
            <h5 className="mx-3 text-sm">Reorder Point</h5>
            <div className="mx-3 text-order flex justify-between">
              <span className="font-bold">1300</span>
              <span style={{ color: "#476EF8" }}>Edit</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemOverviewPage;
